import { DataService } from "./../../../../@core/utils/data.service";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import {
  SettingsClient,
  GetSettingValuesCompanyQuery,
  NumberClient,
  ExtensionClient,
  AllExtensionDto,
} from "../../../../System-api";
import { FormControl, UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { CreateExtensionsComponent } from "../create-extensions/create-extensions.component";
import { map, Observable, of, startWith } from "rxjs";
import { GenerateQRComponent } from "../../../../shared/generate-qr/generate-qr.component";

@Component({
  selector: "choose-test-number",
  templateUrl: "choose-test-number.component.html",
  styleUrls: ["./choose-test-number.component.scss"],
})
export class ChooseTestNumberComponent implements OnInit, OnDestroy {
  @Input() isPreview: boolean = false;
  @Input() loadingButton: boolean = false;
  @Output() run = new EventEmitter<any>();
  phoneNumbers: any[];
  extensions: any[];
  filteredExtensionOptions$: Observable<string[]>;
  filteredOutgoingOptions$: Observable<string[]>;
  selectedOutGoingNumber: string;
  callPhoneNumber: string;
  makeCallForm: UntypedFormGroup;
  isDebug = false;
  constructor(
    @Optional() private ref: NbDialogRef<any>,
    private settingClient: SettingsClient,
    private phoneNumberClient: NumberClient,
    private dataService: DataService,
    private formBuilder: RxFormBuilder,
    private dialogService: NbDialogService,
    private extensionClient: ExtensionClient
  ) {
    this.makeCallForm = this.formBuilder.group({
      outGoingNumber: new FormControl("", [RxwebValidators.required()]),
      number: new FormControl("", [
        RxwebValidators.required(),
        RxwebValidators.maxLength({
          value: 15,
        }),
        RxwebValidators.digit(),
      ]),
    });
  }
  getOne() {
    this.dialogService
      .open(CreateExtensionsComponent, {
        autoFocus: false,
        context: {},
      })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.callPhoneNumber = rs;
          this.getExtensions();
        }
      });
  }
  ngOnDestroy(): void {
    this.dataService.selectedOutGoingNumber = this.selectedOutGoingNumber;
    this.dataService.callPhoneNumber = this.callPhoneNumber;
  }
  getPhoneNumbers() {
    this.phoneNumberClient.getNumbersByCompany().subscribe((item) => {
      this.phoneNumbers = item.trunks;
      this.phoneNumbers.unshift({ name: "Local SIP" });
      this.filteredOutgoingOptions$ = of(this.phoneNumbers);
      this.filteredOutgoingOptions$ = this.makeCallForm.controls[
        "outGoingNumber"
      ].valueChanges.pipe(
        startWith(""),
        map((filterString) => this.filterOut(filterString))
      );
      if (
        !this.dataService.selectedOutGoingNumber &&
        !this.dataService.callPhoneNumber
      ) {
        let settingKeys = new GetSettingValuesCompanyQuery({
          keys: ["OutgoingTestNumber", "TestNumber"],
          companyId: 0,
        });
        this.settingClient.getValues(settingKeys).subscribe((data) => {
          let outGoingSetting = data
            .filter((common) => common.key === "OutgoingTestNumber")
            .pop();
          if (outGoingSetting) {
            this.selectedOutGoingNumber = outGoingSetting.value;
          } else {
            this.selectedOutGoingNumber = "Local SIP";
          }
          let testCallNumberSetting = data
            .filter((common) => common.key === "TestNumber")
            .pop();
          if (testCallNumberSetting) {
            this.callPhoneNumber = testCallNumberSetting.value;
          } else {
            this.callPhoneNumber = "";
          }
        });
      } else {
        this.selectedOutGoingNumber = this.dataService.selectedOutGoingNumber;
        this.callPhoneNumber = this.dataService.callPhoneNumber;
      }
    });
  }
  getExtensions() {
    this.extensionClient.getAllExtension().subscribe(
      (rs) => {
        if (rs) {
          this.extensions = rs.extensions;
          this.filteredExtensionOptions$ = of(this.extensions);
          this.filteredExtensionOptions$ = this.makeCallForm.controls[
            "number"
          ].valueChanges.pipe(
            startWith(""),
            map((filterString) => this.filter(filterString))
          );
        }
      },
      (err) => {}
    );
  }
  private filter(value: string): string[] {
    return this.extensions.filter((e) => e.extension.includes(value));
  }
  private filterOut(value: string): string[] {
    return this.phoneNumbers.filter((e) => e.name.includes(value));
  }
  ngOnInit() {
    this.getPhoneNumbers();
    this.getExtensions();
  }
  dismiss() {
    this.ref.close(false);
  }
  makeCall(isDebug = false) {
    if (this.callPhoneNumber && this.selectedOutGoingNumber) {
      if (this.isPreview == false) {
        this.ref.close({
          outGoingNumber:
            this.selectedOutGoingNumber == "Local SIP"
              ? "101"
              : this.selectedOutGoingNumber,
          callPhoneNumber: this.callPhoneNumber,
          isDebug: isDebug,
        });
      } else {
        this.run.emit({
          outGoingNumber:
            this.selectedOutGoingNumber == "Local SIP"
              ? "101"
              : this.selectedOutGoingNumber,
          callPhoneNumber: this.callPhoneNumber,
          isDebug: isDebug,
        });
      }
    }
  }
  onSelectedChange(event) {
    this.selectedOutGoingNumber = event;
  }
  onCallPhoneChange(event) {
    if (event) {
      if (typeof event !== "string") {
        this.callPhoneNumber = event.extension;
      } else {
        this.callPhoneNumber = event;
      }
    }
  }
  onOutPhoneChange(event) {
    if (event) {
      if (typeof event !== "string") {
        this.selectedOutGoingNumber = event.name;
      } else {
        this.selectedOutGoingNumber = event;
      }
    }
  }
}
