import { Clipboard } from "@angular/cdk/clipboard";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
} from "@nebular/theme";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { ModalScriptComponent } from "./modal-script/modal-script.component";

@Component({
  selector: "generate-script",
  templateUrl: "./generate-script.component.html",
  styleUrls: ["./generate-script.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerateScriptComponent implements OnInit {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  prominence = [
    { name: "Button", value: "0", des: "Show only button to launch the chat." },
    {
      name: "Chat plugin window",
      value: "1",
      des: "Automatically expand the entire Chat Plugin window on the website.",
    },
  ];
  triggers = [
    {
      name: "Site Access",
      value: "1",
      des: "Trigger chat flow when the site is accessed.",
    },
    {
      name: "User Interaction",
      value: "0",
      des: "Trigger chat flow when the user starts chatting with the popup.",
    },
  ];
  restarts = [
    {
      name: "Reset Conversation On Site Access",
      value: "1",
      des: "Trigger reset conversation data when the site is accessed.",
    },
    {
      name: "Preserve Conversation Data",
      value: "0",
      des: "Preserve previous conversation data.",
    },
  ];
  siteArray = [
    { name: "Right", value: "0" },
    {
      name: "Left",
      value: "1",
    },
  ];
  chatToken = "";
  src = "";
  script = "";
  show = "0";
  trigger = "1";
  restart = "1";
  color = "#1FAD53";
  posA = "20";
  posB = "20";
  width = "375";
  height = "65";
  icon = "60";
  site = "0";
  name = "";
  logo = "";
  greeting = "";
  dataCallFlow = null;
  form: UntypedFormGroup;
  logoDefault = "../../../assets/images/logo-bot-default.png";
  selectedChatIcon = [];
  selectedChatShape = [];
  selectedChatMinimize = [];

  constructor(
    protected ref: NbDialogRef<GenerateScriptComponent>,
    private clipboard: Clipboard,
    private toastrService: NbToastrService,
    private formBuilder: RxFormBuilder,
    private dialogService: NbDialogService,
    private cd: ChangeDetectorRef
  ) {
    this.createForm();
  }

  copy() {
    this.clipboard.copy(this.script);
    this.toastrService.show("Copied to Clipboard", "Notification", {
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      status: "success",
    });
  }

  ngOnInit() {
    this.name =
      this.dataCallFlow.callSettings[0].botName?.trim() ||
      this.dataCallFlow.name?.trim();
    this.logo = this.dataCallFlow.callSettings[0].botLogo?.trim() || "";
    this.greeting =
      this.dataCallFlow.callSettings[0].botGreetingMessages?.trim() || "";
    if (this.chatToken) {
      const searchStr = "botconnector/";
      const index = this.chatToken.indexOf(searchStr);
      if (index !== -1) {
        this.src =
          this.chatToken.substring(0, index + searchStr.length) +
          "static/js/primas.chat.min.js";
      }
    }
    this.update();
  }
  dismiss() {
    this.ref.close();
  }

  showPopover() {
    this.popover?.toggle();
  }
  view() {}
  update() {
    let defaultName =
      this.dataCallFlow.callSettings[0].botName?.trim() ||
      this.dataCallFlow.name?.trim();
    let logo = this.logo?.trim() ? "&logoUrl=" + this.logo.trim() : "";
    let logoParams = this.logo?.trim()
      ? `logo-chatbox="` + this.logo.trim() + `"`
      : "";
    let greetingMessages = this.greeting?.trim()
      ? `&greetingMessage=` + this.greeting.trim()
      : "";
    let trigger =
      this.trigger?.trim() === "0"
        ? `&triggerChatFlow=` + this.trigger.trim()
        : "";
    let restart =
      this.restart?.trim() === "0" ? `&restartConv=` + this.restart.trim() : "";
    let name = this.name?.trim()
      ? "&name=" + this.name.trim()
      : "&name=" + defaultName;
    this.script = `<script id="primas-chat-script" type="text/javascript" src="${
      this.src
    }"
    chat-token="${
      this.chatToken +
      "&displayControl=1" +
      name +
      logo +
      greetingMessages +
      trigger +
      restart
    }" pos-a="${this.posA || "20"}" pos-b="${this.posB || "20"}"
    c-color="${this.color.slice(1) || "1FAD53"}" p-width="${
      this.width || "375"
    }" p-height="${this.height || "65"}" c-size="${this.icon || "60"}" show="${
      this.show || "0"
    }" c-site="${this.site || "1"}" c-icon="${
      this.selectedChatIcon[0]?.trim() || "1"
    }" c-shape="${this.selectedChatShape[0]?.trim() || "1"}" c-minimize="${
      this.selectedChatMinimize[0]?.trim() || "1"
    }" ${logoParams} ></script>`;
  }
  reset() {
    this.show = "0";
    this.trigger = "1";
    this.color = "#1FAD53";
    this.posA = "20";
    this.posB = "20";
    this.width = "375";
    this.height = "65";
    this.icon = "60";
    this.site = "0";
    this.name =
      this.dataCallFlow.callSettings[0].botName?.trim() ||
      this.dataCallFlow.name?.trim();
    this.logo = this.dataCallFlow.callSettings[0].botLogo?.trim() || "";
    this.greeting =
      this.dataCallFlow.callSettings[0].botGreetingMessages?.trim() || "";
    this.update();
  }
  done() {
    this.update();
    this.dialogService
      .open(ModalScriptComponent, {
        autoFocus: false,
        context: { script: this.script },
      })
      .onClose.subscribe((rs) => {
        if (rs == false) {
          this.dismiss();
        }
      });
  }
  updateSelectedChatIcon(value): void {
    this.selectedChatIcon = value;
    this.cd.markForCheck();
  }
  updateSelectedChatMinimize(value): void {
    this.selectedChatMinimize = value;
    this.cd.markForCheck();
  }
  updateSelectedChatShape(value): void {
    this.selectedChatShape = value;
    this.cd.markForCheck();
  }

  createForm() {
    this.form = this.formBuilder.group({
      show: [""],
      trigger: [""],
      site: [""],
      color: [""],
      name: [""],
      logo: [""],
      greeting: [""],
      width: [
        "",
        [
          RxwebValidators.required(),
          RxwebValidators.maxLength({
            value: 3,
          }),
          RxwebValidators.digit(),
        ],
      ],
      height: ["", [RxwebValidators.required(), RxwebValidators.digit()]],
      posB: [
        "",
        [
          RxwebValidators.required(),
          RxwebValidators.maxLength({
            value: 3,
          }),
          RxwebValidators.digit(),
        ],
      ],
      posA: [
        "",
        [
          RxwebValidators.required(),
          RxwebValidators.maxLength({
            value: 3,
          }),
          RxwebValidators.digit(),
        ],
      ],
      icon: [
        "",
        [
          RxwebValidators.required(),
          RxwebValidators.maxLength({
            value: 3,
          }),
          RxwebValidators.digit(),
        ],
      ],
    });
  }
}
