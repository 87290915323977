<nb-card>
  <nb-card-header class="infor-header">
    <div class="d-flex justify-content-between">
      Testing QR Code
      <button
        (click)="dismiss()"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body style="width: 500px; padding-top: 0">
    <nb-tabset fullWidth>
      <nb-tab tabTitle="Chat">
        <div
          class="qrcodeImage center"
          style="
            width: 100%;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1rem;
          "
        >
          <qrcode
            [qrdata]="testChatUrl"
            [allowEmptyString]="true"
            [ariaLabel]="'QR Code image with the following content...'"
            [cssClass]="'center'"
            [colorDark]="'#000000ff'"
            [colorLight]="'#ffffffff'"
            [elementType]="'canvas'"
            [errorCorrectionLevel]="'M'"
            [imageSrc]="logoDefault"
            [imageHeight]="50"
            [imageWidth]="50"
            [margin]="4"
            [scale]="1"
            [title]="testChatUrl"
            [width]="200"
            (qrCodeURL)="onChangeURL($event)"
          ></qrcode>
          <div style="display: flex; gap: 0.5rem">
            <div class="downloadButton">
              <button
                matTooltip="Download QR Code image"
                nbButton
                type="button"
                status="primary"
                (click)="saveAsImage()"
              >
                Download QR Code
                <nb-icon icon="download-outline"></nb-icon>
              </button>
            </div>
            <div>
              <a
                [href]="testChatUrl"
                target="_blank"
                rel="noopener noreferrer"
                style="display: block; width: 100%; height: 100%"
              >
                <button
                  matTooltip="Open link"
                  nbButton
                  status="basic"
                  outline
                  style="height: 100%"
                  size="small"
                  class="copy-button"
                >
                  Open link
                  <nb-icon icon="external-link-outline"></nb-icon></button
              ></a>
            </div>
          </div>
        </div>
        <div class="label-script" style="display: flex; gap: 0.5rem">
          <nb-form-field style="width: 100%">
            <input nbInput fullWidth [(ngModel)]="testChatUrl" readonly />
            <button
              nbSuffix
              nbButton
              ghost
              matTooltip="Copy"
              (click)="copy(testChatUrl)"
            >
              <nb-icon icon="copy"></nb-icon>
            </button>
          </nb-form-field>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Call" [active]="activeTab === 1">
        <div
          class="qrcodeImage center"
          style="
            width: 100%;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1rem;
          "
        >
          <qrcode
            [qrdata]="testCallUrl"
            [allowEmptyString]="true"
            [ariaLabel]="'QR Code image with the following content...'"
            [cssClass]="'center'"
            [colorDark]="'#000000ff'"
            [colorLight]="'#ffffffff'"
            [elementType]="'canvas'"
            [errorCorrectionLevel]="'M'"
            [imageSrc]="logoDefault"
            [imageHeight]="50"
            [imageWidth]="50"
            [margin]="4"
            [scale]="1"
            [title]="testCallUrl"
            [width]="200"
            (qrCodeURL)="onChangeURL($event)"
          ></qrcode>
          <div style="display: flex; gap: 0.5rem">
            <div class="downloadButton">
              <button
                matTooltip="Download QR Code image"
                nbButton
                type="button"
                status="primary"
                (click)="saveAsImage()"
              >
                Download QR Code
                <nb-icon icon="download-outline"></nb-icon>
              </button>
            </div>
            <div>
              <a
                [href]="testCallUrl"
                target="_blank"
                rel="noopener noreferrer"
                style="display: block; width: 100%; height: 100%"
              >
                <button
                  matTooltip="Open link"
                  nbButton
                  status="basic"
                  outline
                  style="height: 100%"
                  size="small"
                  class="copy-button"
                >
                  Open link
                  <nb-icon icon="external-link-outline"></nb-icon></button
              ></a>
            </div>
          </div>
        </div>
        <div class="label-script" style="display: flex; gap: 0.5rem">
          <nb-form-field style="width: 100%">
            <input nbInput fullWidth [(ngModel)]="testCallUrl" readonly />
            <button
              nbSuffix
              nbButton
              ghost
              matTooltip="Copy"
              (click)="copy(testCallUrl)"
            >
              <nb-icon icon="copy"></nb-icon>
            </button>
          </nb-form-field>
        </div>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
</nb-card>
