import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { GetInput } from "../../../../@core/model/get-input";
import { Observable, Subject, Subscription, map, of } from "rxjs";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
  NbWindowRef,
} from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { SimpleAudioPlayerModalComponent } from "../../../../shared/simple-audio-player-modal/simple-audio-player-modal.component";
import {
  AudioClient,
  CustomFunctionClient,
  GrammarClient,
} from "../../../../System-api";
import { SimpleAudioPlayerComponent } from "../../../../shared/simple-audio-player/simple-audio-player.component";
import { SignalRService } from "../../../../@core/utils/signalR.service";
import { TextToSpeechComponent } from "../../../../shared/text-to-speech/text-to-speech.component";
import { UntypedFormGroup } from "@angular/forms";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { MultipleGrammarComponent } from "../../other/mutiple-grammar/multiple-grammar.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Clipboard } from "@angular/cdk/clipboard";

@Component({
  selector: "ngx-get-input",
  templateUrl: "./get-input.component.html",
  styleUrls: ["./get-input.component.scss"],
})
export class GetInputComponent implements OnInit, OnDestroy {
  @ViewChild("item") accordion;
  @ViewChild("audioPlayer", {
    static: true,
  })
  audioPlayer: SimpleAudioPlayerComponent;
  @ViewChild("audioPlayerRepeat", {
    static: true,
  })
  audioPlayerRepeat: SimpleAudioPlayerComponent;
  @ViewChild("audioPlayerConfirm", {
    static: true,
  })
  audioPlayerConfirm: SimpleAudioPlayerComponent;
  @ViewChild("audioNotMatchPlayer", {
    static: true,
  })
  audioNotMatchPlayer: SimpleAudioPlayerComponent;
  @ViewChild("audioNoInputPlayer", {
    static: true,
  })
  audioNoInputPlayer: SimpleAudioPlayerComponent;
  @ViewChild("textToSpeech", {
    static: true,
  })
  textToSpeech: TextToSpeechComponent;

  @ViewChild("textToSpeechRepeat", {
    static: true,
  })
  textToSpeechRepeat: TextToSpeechComponent;
  @ViewChild("textToSpeechConfirm", {
    static: true,
  })
  textToSpeechConfirm: TextToSpeechComponent;
  @ViewChild("textToSpeechNotMatch", {
    static: true,
  })
  textToSpeechNotMatch: TextToSpeechComponent;
  @ViewChild("textToSpeechNoInput", {
    static: true,
  })
  textToSpeechNoInput: TextToSpeechComponent;
  @ViewChild("autoInput") input;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild("multipleGrammar", {
    static: true,
  })
  multipleGrammar: MultipleGrammarComponent;
  @ViewChild("listElement") listElement: ElementRef;
  cell: MxCell;
  graph: MxGraph;
  data: GetInput = new GetInput();
  selectedSubscription: Subscription;
  eventsSubject: Subject<void> = new Subject<void>();
  eventsSubjectRepeat: Subject<void> = new Subject<void>();
  eventsSubjectNotMatch: Subject<void> = new Subject<void>();
  eventsSubjectNoInput: Subject<void> = new Subject<void>();
  eventsSubjectConfirm: Subject<void> = new Subject<void>();
  checked: any = false;
  grammarsData: any = [];
  dataTTS: any = [];
  dataTTSCB: any = [];
  dataTTSNotMatch: any = [];
  dataTTSNoInput: any = [];
  options: object[] = [
    { id: 1, name: "Audio From Library" },
    { id: 2, name: "Text To Speech or Chat Text" },
  ];
  listCustomFunction = [];
  attributes: string[] = this.dataService.ivrAttributeArray;
  attributeEntities: string[] = this.dataService.ivrAttributeArray;
  grammarStatusChangedSubscription: Subscription;
  grammarNameTemp: any = "";
  isLoading = false;
  isLoadingGrammar = false;
  isGotGrammar = false;
  keyNumberRepeatForm: UntypedFormGroup;
  answer: any[] = [];
  listNation = [];
  listKeyNumber: object[] = [
    { value: "-1", name: "Not Use" },
    { value: "0", name: "0" },
    { value: "1", name: "1" },
    { value: "2", name: "2" },
    { value: "3", name: "3" },
    { value: "4", name: "4" },
    { value: "5", name: "5" },
    { value: "6", name: "6" },
    { value: "7", name: "7" },
    { value: "8", name: "8" },
    { value: "9", name: "9" },
    { value: "*", name: "*" },
  ];
  icon = "plus-outline";
  errorMessage: string = "Intent is required.";
  cancelAction = false;
  searchText: any = "";
  grammarArray = [];
  grammarDataFilter = [];
  timeout = null;
  currentKeywordsRepeat = "";
  optionsAnswer: object[] = [
    { id: 1, name: "Default" },
    { id: 2, name: "Use Variable" },
  ];
  copyTitle = "Copy to clipboard";
  example = [
    {
      buttonValue: "yes",
      buttonText: [
        { language: "en", value: "Yes" },
        { language: "vi", value: "Vâng" },
      ],
    },
    {
      buttonValue: "no",
      buttonText: [
        {
          language: "en",
          value: "No, thanks!",
        },
        {
          language: "vi",
          value: "Không, cảm ơn!",
        },
      ],
    },
  ];
  constructor(
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private dialogService: NbDialogService,
    private grammarClient: GrammarClient,
    private customFunctionClient: CustomFunctionClient,
    private toastrService: NbToastrService,
    private signalRService: SignalRService,
    private audioClient: AudioClient,
    private graphService: GraphHandlerService,
    private clipboard: Clipboard
  ) {}
  convertToNewDataFormat() {
    if (!this.data.content) {
      this.data.content = this.data.text;
      this.data.contentRepeat = this.data.textRepeat;
      this.data.contentChatBot = this.data.textChatBot;
      this.data.contentNotMatch = this.data.textNotMatch;
      this.data.contentNoInput = this.data.textNoInput;
      this.data.contentConfirm = this.data.textConfirm;
      this.data.text = "";
      this.data.textRepeat = "";
      this.data.textChatBot = "";
      this.data.textNotMatch = "";
      this.data.textNoInput = "";
      this.data.textConfirm = "";
    }
  }
  tagChange(event) {
    const tag = event.tag;
    (this.data.keywordsRepeat as any)[tag.index].value = tag.value;
    (this.data.keywordsRepeat as any)[tag.index].display = tag.value;
  }
  filterData(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let val = "";
      if (event != null) {
        val = event.target.value.toLowerCase();
      }
      this.grammarsData = this.grammarDataFilter.filter((x) =>
        x.name.toLowerCase().includes(val)
      );
    }, 200);
  }

  handleOnlyIntegerNumber(event) {
    if (event.target?.value != null) {
      var number = event.target.value.toString();
      if (number.match("^[0-9]*$")) {
        this.data[event.target.attributes.id.value] = number;
      }
    }
  }
  getAnswerVariable($event) {
    this.data.answerCustom = $event;
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngAfterViewInit(): void {
    this.data.grammarName = this.grammarNameTemp;
  }
  ngOnInit() {
    this.grammarStatusChangedSubscription =
      this.signalRService.statusGrammarChanged.subscribe((rs) => {
        this.notify(rs);
        this.multipleGrammar.getGrammarData();
      });
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] =
        this.cell.getAttribute(key) != "undefined"
          ? this.cell.getAttribute(key)
          : "";
    }
    this.convertToNewDataFormat();
    this.handleSubscribeAudio();
    this.handleSubscribeTTS();
    this.getAnswerAction();
    if (this.data.idGrammar) {
      this.data.idGrammar =
        Number(this.data.idGrammar) == 0 || isNaN(this.data.idGrammar)
          ? null
          : Number(this.data.idGrammar);
    } else {
      this.data.idGrammar = null;
    }
    this.handleSetTypeTTS();
    this.handleShowButtonAudio();
    if (
      this.data.audioId ||
      this.data.audioIdRepeat ||
      this.data.audioNotMatchId ||
      this.data.audioNoInputId ||
      this.data.audioConfirmId
    ) {
      this.audioClient.get().subscribe((audioVm) => {
        this.audioPlayer.listAudioInAction = audioVm.audios;
        this.audioPlayerRepeat.listAudioInAction = audioVm.audios;
        this.audioPlayerConfirm.listAudioInAction = audioVm.audios;
        this.audioNotMatchPlayer.listAudioInAction = audioVm.audios;
        this.audioNoInputPlayer.listAudioInAction = audioVm.audios;
        this.audioPlayer.playSelectedAudio(this.data.audioId);
        this.audioPlayerRepeat.playSelectedAudio(this.data.audioIdRepeat);
        this.audioPlayerConfirm.playSelectedAudio(this.data.audioConfirmId);
        this.audioNotMatchPlayer.playSelectedAudio(this.data.audioNotMatchId);
        this.audioNoInputPlayer.playSelectedAudio(this.data.audioNoInputId);
      });
    }
    if (this.data.userResponse?.trim() == "" || !this.data.userResponse) {
      this.data.userResponse = null;
    }
    if (this.data.attribute?.trim() == "" || !this.data.attribute) {
      this.data.attribute = null;
    }
    if (this.data.entityAttribute?.trim() == "" || !this.data.entityAttribute) {
      this.data.entityAttribute = null;
    }
    if (this.data.noInputTimeout?.trim() == "" || !this.data.noInputTimeout) {
      this.data.noInputTimeout = "5";
    }
    if (
      this.data.noInputSpeechTimeout?.trim() == "" ||
      !this.data.noInputSpeechTimeout
    ) {
      this.data.noInputSpeechTimeout = "5";
    }
    if (this.data.minLength?.trim() == "" || !this.data.minLength) {
      this.data.minLength = "1";
    }
    if (this.data.minLengthSpeech?.trim() == "" || !this.data.minLengthSpeech) {
      this.data.minLengthSpeech = "1";
    }
    if (
      this.data.repeat?.trim() == "" ||
      this.data.repeat?.trim() == "0" ||
      !this.data.repeat
    ) {
      this.data.repeat = "2";
    }
    if (this.data.chatTimeout?.trim() == "" || !this.data.chatTimeout) {
      this.data.chatTimeout = "0";
    }
    if (this.data.option) {
      this.handleAudio(Number(this.data.option));
    }
    if (this.data.translateGrammar == undefined) {
      this.data.translateGrammar = false;
    }
    if (this.data.engine == undefined) {
      this.data.engine = false;
    }
    if (this.data.grammarArray) {
      this.grammarArray = JSON.parse(this.data.grammarArray);
    }
    if (this.data.keywordsRepeat) {
      this.data.keywordsRepeat = JSON.parse(this.data.keywordsRepeat);
    } else {
      this.data.keywordsRepeat = "[]";
      this.data.keywordsRepeat = JSON.parse(this.data.keywordsRepeat);
    }
    if (this.data.content) {
      this.textToSpeech.selectCurrentTTS(this.data.content);
    }
    if (this.data.contentChatBot) {
      this.textToSpeech.selectCurrentTTSBot(this.data.contentChatBot);
    }
    if (this.data.contentNotMatch) {
      this.textToSpeechNotMatch.selectCurrentTTS(this.data.contentNotMatch);
    } else {
      this.textToSpeechNotMatch.checkNull = true;
    }
    if (this.data.contentNoInput) {
      this.textToSpeechNoInput.selectCurrentTTS(this.data.contentNoInput);
    } else {
      this.textToSpeechNoInput.checkNull = true;
    }
    if (this.data.contentRepeat) {
      this.textToSpeechRepeat.selectCurrentTTS(this.data.contentRepeat);
    } else {
      this.textToSpeechRepeat.checkNull = true;
    }
    if (this.data.contentConfirm) {
      this.textToSpeechConfirm.selectCurrentTTS(this.data.contentConfirm);
    } else {
      this.textToSpeechConfirm.checkNull = true;
    }
    if (this.data.spelling) {
      this.data.spelling = this.data.spelling.toString() === "true";
    } else {
      this.data.spelling = false;
    }
    if (this.data.spellingPosition) {
    } else {
      this.data.spellingPosition = "2";
    }
    if (this.data.allowSpeech) {
      this.data.allowSpeech = this.data.allowSpeech.toString() === "true";
    } else {
      this.data.allowSpeech = false;
    }
    if (this.data.keyNumberRepeat == "") {
      this.data.keyNumberRepeat = "-1";
    }
    if (this.data.rememberRepeat) {
      this.data.rememberRepeat = this.data.rememberRepeat.toString() === "true";
    } else {
      this.data.rememberRepeat = false;
    }
    if (this.data.isConfirm) {
      this.data.isConfirm = this.data.isConfirm.toString() === "true";
    } else {
      this.data.isConfirm = false;
    }
    if (this.data.log) {
      this.data.log = this.data.log.toString() === "true";
    } else {
      this.data.log = false;
    }
    if (this.data.allowAnswerOption) {
      this.data.allowAnswerOption =
        this.data.allowAnswerOption.toString() === "true";
    } else {
      this.data.allowAnswerOption = false;
    }

    if (!this.data.answerOptionType) {
      this.data.answerOptionType = 1;
    }
    this.handleAnswerOptionType(Number(this.data.answerOptionType));
    if (this.data.allowBreak) {
      this.data.allowBreak = this.data.allowBreak.toString() === "true";
    } else {
      this.data.allowBreak = false;
    }
    this.textToSpeech.isChatBot = true;
    if (this.data.idGrammar) {
      this.handleOldData(this.data.typeGrammar, this.data.idGrammar);
    }
    if (this.data.contentRepeat) {
      this.textToSpeechRepeat.selectCurrentTTS(this.data.contentRepeat);
    }
    if (this.data.skipIfAttributeExists) {
      this.data.skipIfAttributeExists =
        this.data.skipIfAttributeExists.toString() === "true";
    } else {
      this.data.skipIfAttributeExists = false;
    }
    this.data.attributeToSkip = this.data?.attributeToSkip || "";
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  resetOldData() {
    this.data.typeGrammar = "BuiltIn";
    this.data.idGrammar = 0;
    this.data.grammar = "";
    this.data.grammarName = "";
    this.data.functionId = "";
    this.data.params = "";
  }
  private filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.attributes.filter((optionValue) =>
      optionValue.toLowerCase().includes(filterValue)
    );
  }
  private filterGrammar(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.grammarsData.filter((optionValue) =>
      optionValue.name.toLowerCase().includes(filterValue)
    );
  }
  getFilteredOptions(value: string): Observable<any[]> {
    return of(value).pipe(
      map((filterString) => this.filterGrammar(filterString))
    );
  }
  copyAttribute() {
    this.clipboard.copy(JSON.stringify(this.example, undefined, 2));
    this.copyTitle = "Copied to clipboard";
  }
  handleSentiment($event, type) {
    switch (type) {
      case "main":
        this.data.sentiment = $event;
        break;
      case "noInput":
        this.data.sentimentNoInput = $event;
        break;
      case "notMatch":
        this.data.sentimentNotMatch = $event;
        break;
      default:
        break;
    }
  }

  handleAudio(value: number) {
    this.data.option = value;
  }

  disableEvent(event) {
    event.stopPropagation();
  }

  allowAnswerOption($event) {
    if ($event && this.answer.length == 0) {
      this.addNewAnswer();
    } else {
      this.scrollToBottom();
    }
    this.data.allowAnswerOption = $event;
  }
  allowSpelling($event) {
    this.data.spelling = $event;
  }
  allowSkip($event) {
    this.data.skipIfAttributeExists = $event;
  }

  handleAnswerOptionType(value: number) {
    this.data.answerOptionType = value;
  }
  allowRememberRepeat($event) {
    this.data.rememberRepeat = $event;
  }
  allowLog($event) {
    this.data.log = $event;
  }
  allowConfirm($event) {
    this.data.isConfirm = $event;
  }

  getAnswerAction() {
    this.listNation = JSON.parse(this.dataService.CallSetting.languageSetting);
    this.data.answer = this.data.answer || "[]";
    let answerOption = JSON.parse(this.data.answer);
    if (answerOption && answerOption.length > 0) {
      this.listNation.forEach((obj) => {
        for (let i = 0; i < answerOption.length; i++) {
          let check = 0;
          for (let j = 0; j < answerOption[i].value.length; j++) {
            const languageSetting = obj.language.split("-");
            const languageTTS = answerOption[i].value[j].language.split("-");
            if (languageSetting[0] == languageTTS[0]) {
              answerOption[i].value[j].name = obj.name;
              answerOption[i].value[j].language = obj.language;
              check = 1;
            }
          }
          if (check == 0) {
            answerOption[i].value.push({
              name: obj.name,
              language: obj.language,
              value: "",
            });
          }
        }
      });
      for (let k = 0; k < answerOption.length; k++) {
        for (let z = 0; z < answerOption[k].value.length; z++) {
          let check = 0;
          this.listNation.forEach((item) => {
            const languageSetting =
              answerOption[k].value[z].language.split("-");
            const languageTTS = item.language.split("-");
            if (languageSetting[0] == languageTTS[0]) {
              check = 1;
            }
          });
          if (check == 0) {
            if (z != 0) {
              answerOption[k].value.splice(z, 1);
              z--;
            } else {
              answerOption[k].value.shift();
              z--;
            }
          }
        }
      }
      this.answer = answerOption;
    }
  }
  removeAnswer(z) {
    if (z != 0) {
      this.answer.splice(z, 1);
    } else {
      this.answer.shift();
    }
  }
  addNewAnswer() {
    let newLanguage = { name: "", value: [] };
    this.listNation.forEach((nation) => {
      newLanguage.value.push({
        name: nation.name,
        language: nation.language,
        value: "",
      });
    });
    this.answer.push(newLanguage);
    this.scrollToBottom();
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.listElement.nativeElement.scrollTop =
          this.listElement.nativeElement.scrollHeight;
      }, 10);
    } catch (err) {}
  }
  handleAnswerOption() {
    try {
      let data = this.answer;
      if (data && data?.length > 0) {
        let finalData = data.filter((x) => x.name?.trim() != "");
        if (finalData.length > 0) {
          const trimmedData = finalData.map((item) => {
            return {
              ...item,
              name: item.name.trim(),
            };
          });
          trimmedData.forEach((item, index) => {
            item.value.forEach((value, indexValue) => {
              trimmedData[index].value[indexValue].value =
                trimmedData[index].value[indexValue].value.trim();
            });
          });
          this.data.answer = JSON.stringify(trimmedData);
        } else {
          this.data.answer = "";
        }
      } else {
        this.data.answer = "";
      }
    } catch (error) {
      this.data.answer = "";
    }
  }
  onTextChange(e) {
    this.currentKeywordsRepeat = e;
  }
  updateEmailRecipients() {
    if (this.currentKeywordsRepeat?.trim() != "") {
      let tempArray = this.data.keywordsRepeat as any;
      if (!tempArray) {
        tempArray = [] as any;
      }
      let dataExist = tempArray.find(
        (x: any) => x == this.currentKeywordsRepeat
      );
      if (!dataExist) {
        tempArray.push(this.currentKeywordsRepeat);
      }
      this.data.keywordsRepeat = tempArray;
    }
  }
  submit() {
    if (!this.cancelAction && !this.isLoading) {
      this.updateEmailRecipients();
      this.handleAnswerOption();
      if (
        this.data.timeout == "" ||
        !this.data.timeout ||
        parseFloat(this.data.timeout) < 0 ||
        !Number.isInteger(parseFloat(this.data.timeout))
      ) {
        this.data.timeout = "10";
      }
      if (
        this.data.speechTimeout == "" ||
        !this.data.speechTimeout ||
        parseFloat(this.data.speechTimeout) < 0 ||
        !Number.isInteger(parseFloat(this.data.speechTimeout))
      ) {
        this.data.speechTimeout = "10";
      }
      this.data.grammarArray = JSON.stringify(this.grammarArray);
      this.data.keywordsRepeat = JSON.stringify(this.data.keywordsRepeat);
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof value == "boolean" && value == false) {
          value = "false";
        }
        this.cell.setAttribute(key, value || "");
      }
      const getInputCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        getInputCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
  toggle() {
    this.accordion.toggle();
  }

  handleSubscribeTTS() {
    this.selectedSubscription = this.textToSpeech.selectedTTS.subscribe(
      (tts) => {
        this.data.content = JSON.stringify(tts);
      }
    );
    this.selectedSubscription = this.textToSpeech.selectedTTSBot.subscribe(
      (tts) => {
        this.data.contentChatBot = JSON.stringify(tts);
      }
    );
    this.selectedSubscription = this.textToSpeechNotMatch.selectedTTS.subscribe(
      (tts) => {
        this.data.contentNotMatch = JSON.stringify(tts);
      }
    );
    this.selectedSubscription = this.textToSpeechNoInput.selectedTTS.subscribe(
      (tts) => {
        this.data.contentNoInput = JSON.stringify(tts);
      }
    );

    this.selectedSubscription = this.textToSpeechRepeat.selectedTTS.subscribe(
      (tts) => {
        this.data.contentRepeat = JSON.stringify(tts);
      }
    );
    this.selectedSubscription = this.textToSpeechConfirm.selectedTTS.subscribe(
      (tts) => {
        this.data.contentConfirm = JSON.stringify(tts);
      }
    );
  }
  handleSubscribeAudio() {
    this.selectedSubscription = this.audioPlayerRepeat.selectedAudio.subscribe(
      (audio) => {
        let audioPathRepeat = [];
        let audioIdRepeat = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPathRepeat.push(obj.pathAsterisk);
            audioIdRepeat.push(obj.id);
          });
        }

        this.data.audioIdRepeat =
          audio !== undefined ? audioIdRepeat.toString() : "";
        this.data.audioPathRepeat =
          audio !== undefined ? audioPathRepeat.toString() : "";
      }
    );
    this.selectedSubscription = this.audioPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioId = audio !== undefined ? audioId.toString() : "";
        this.data.audioPath = audio !== undefined ? audioPath.toString() : "";
      }
    );
    this.selectedSubscription = this.audioPlayerConfirm.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioConfirmId =
          audio !== undefined ? audioId.toString() : "";
        this.data.audioConfirmPath =
          audio !== undefined ? audioPath.toString() : "";
      }
    );
    this.selectedSubscription =
      this.audioNotMatchPlayer.selectedAudio.subscribe((audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioNotMatchId =
          audio !== undefined ? audioId.toString() : "";
        this.data.audioNotMatchPath =
          audio !== undefined ? audioPath.toString() : "";
      });
    this.selectedSubscription = this.audioNoInputPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioNoInputId =
          audio !== undefined ? audioId.toString() : "";
        this.data.audioNoInputPath =
          audio !== undefined ? audioPath.toString() : "";
      }
    );
  }
  handleShowButtonAudio() {
    this.audioPlayer.showAddButton = true;
    this.audioPlayer.isCallFlowAction = true;
    this.audioPlayerRepeat.showAddButton = true;
    this.audioPlayerRepeat.isCallFlowAction = true;
    this.audioPlayerConfirm.showAddButton = true;
    this.audioPlayerConfirm.isCallFlowAction = true;
    this.audioNotMatchPlayer.showAddButton = true;
    this.audioNotMatchPlayer.isCallFlowAction = true;
    this.audioNoInputPlayer.showAddButton = true;
    this.audioNoInputPlayer.isCallFlowAction = true;
  }
  handleSetTypeTTS() {
    this.textToSpeech.isMainPromptAndCollect = true;
    this.textToSpeech.type = "main";
    this.textToSpeechNoInput.isNoInput = true;
    this.textToSpeechNoInput.type = "no-input";
    this.textToSpeechNotMatch.isNotMatch = true;
    this.textToSpeechNotMatch.type = "not-match";
    this.textToSpeechRepeat.type = "repeat";
    this.textToSpeechConfirm.type = "confirm";
  }
  showModify(type = "main") {
    let audioListId = [];
    switch (type) {
      case "main":
        audioListId = this.data.audioId.split(",");
        break;
      case "no-input":
        audioListId = this.data.audioNoInputId.split(",");
        break;
      case "not-match":
        audioListId = this.data.audioNotMatchId.split(",");
        break;
      case "repeat":
        audioListId = this.data.audioIdRepeat.split(",");
        break;
      case "confirm":
        audioListId = this.data.audioConfirmId.split(",");
        break;
      default:
        break;
    }
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: audioListId,
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubjectAudio(type, rs);
    });
  }
  eventsSubjectAudio(type = "main", rs) {
    switch (type) {
      case "main":
        this.eventsSubject.next(rs);
        break;
      case "no-input":
        this.eventsSubjectNoInput.next(rs);
        break;
      case "not-match":
        this.eventsSubjectNotMatch.next(rs);
        break;
      case "repeat":
        this.eventsSubjectRepeat.next(rs);
        break;
      case "confirm":
        this.eventsSubjectConfirm.next(rs);
        break;
      default:
        break;
    }
  }
  notify(result) {
    if (result) {
      this.toastrService.show("Train successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Train unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  showToast(result) {
    if (result) {
      this.toastrService.show("Delete grammar successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Delete grammar unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  getUserResponse(event) {
    this.data.userResponse = event;
  }
  getAttribute(event) {
    this.data.attribute = event;
  }
  getAttributeToSkip(event) {
    this.data.attributeToSkip = event;
  }
  getEntityAttribute(event) {
    this.data.entityAttribute = event;
  }
  ngOnDestroy() {
    this.grammarStatusChangedSubscription.unsubscribe();
    this.selectedSubscription.unsubscribe();
  }
  handleOldData(type, id) {
    this.isLoadingGrammar = true;
    switch (type) {
      case "Digit":
        this.grammarClient.get(id).subscribe({
          next: (rs) => {
            this.isLoadingGrammar = false;
            if (rs) {
              this.grammarArray = [
                ...this.grammarArray,
                {
                  id: rs.id,
                  name: rs.name,
                  type: "Digit",
                },
              ];
            }
            this.resetOldData();
          },
          error: () => {
            this.isLoadingGrammar = false;
            this.resetOldData();
          },
        });
        break;
      case "BuiltIn":
        this.customFunctionClient.getAllFunctionBuildIn().subscribe({
          next: (result) => {
            this.isLoadingGrammar = false;
            if (result != null) {
              let value = result.functions.find((x) => x.id == id);
              if (value) {
                this.grammarArray = [
                  ...this.grammarArray,
                  {
                    id: value.id,
                    name: this.matchBuildinName(value.name),
                    type: "BuiltIn",
                  },
                ];
              }
            }
            this.resetOldData();
          },
          error: (err) => {
            this.isLoadingGrammar = false;
            this.resetOldData();
          },
        });
        break;
      case "Function":
        this.customFunctionClient.get(id).subscribe({
          next: (value) => {
            this.isLoadingGrammar = false;
            if (value) {
              this.grammarArray = [
                ...this.grammarArray,
                {
                  id: value.id,
                  name: value.name,
                  type: "Function",
                },
              ];
            }
            this.resetOldData();
          },
          error: (error) => {
            this.isLoadingGrammar = false;
            this.resetOldData();
          },
        });
        break;
      case "Custom":
        this.grammarClient.get(id).subscribe({
          next: (value) => {
            this.isLoadingGrammar = false;
            if (value) {
              this.grammarArray = [
                ...this.grammarArray,
                {
                  id: value.id,
                  name: value.name,
                  type: "Custom",
                },
              ];
            }
            this.resetOldData();
          },
          error: () => {
            this.isLoadingGrammar = false;
            this.resetOldData();
          },
        });
        break;
      default:
        this.isLoadingGrammar = false;
        break;
    }
  }
  matchBuildinName(name) {
    switch (name) {
      case "biYesno": {
        return "Yes or No";
      }
      case "biDate": {
        return "Date (mm-dd-yyyy)";
      }
      case "biPhonenumber": {
        return "Phone number";
      }
      case "biDigit": {
        return "Number";
      }
      case "biEmail": {
        return "Email";
      }
      default: {
        return name;
      }
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.answer, event.previousIndex, event.currentIndex);
  }
}
