import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Clipboard } from "@angular/cdk/clipboard";
import { SafeUrl } from "@angular/platform-browser";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
} from "@nebular/theme";
import { CallFlowClient, GenerateLinkTestingCommand } from "../../System-api";

@Component({
  selector: "generate-qr",
  templateUrl: "./generate-qr.component.html",
  styleUrls: ["./generate-qr.component.scss"],
})
export class GenerateQRComponent implements OnInit {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  logoDefault = "../../../assets/images/logo-bot-default.png";
  qrCodeDownloadLink: SafeUrl = "";
  testChatUrl = "";
  testCallUrl = "";
  data: any;
  activeTab: number = 0;

  constructor(
    private callflowClient: CallFlowClient,
    protected ref: NbDialogRef<GenerateQRComponent>,
    private clipboard: Clipboard,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    let botName = this.data.callSettings[0]?.botName?.trim() || this.data.name;
    let logo = this.data.callSettings[0]?.botLogo?.trim()
      ? "&logoUrl=" + this.data.callSettings[0].botLogo.trim()
      : "";
    let greeting = this.data.callSettings[0]?.botGreetingMessages?.trim()
      ? "&greetingMessage=" +
        this.data.callSettings[0].botGreetingMessages.trim()
      : "";
    this.testChatUrl = this.data.chatUrl + "&name=" + botName + logo + greeting;

    this.callflowClient
      .generateLinkTesting(
        new GenerateLinkTestingCommand({
          id: this.data.id,
          callFlowJson: this.data.jsonFormat,
          callSettings: JSON.stringify(this.data.callSettings[0]),
          attribute: this.data.attribute,
          name:
            this.data.callSettings[0].botName?.trim() ||
            this.data.callSettings[0].callFlowName?.trim() ||
            "Primas Bot",
        })
      )
      .subscribe({
        next: (rs) => {
          this.testCallUrl = rs;
        },
        error: (error) => {
          this.toastrService.show(
            "Something went wrong. Please try again later.",
            `Notification`,
            {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "danger",
            }
          );
        },
      });
  }
  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  saveAsImage() {
    let parentElement = null;
    parentElement = document.querySelector("canvas").toDataURL("image/png");
    if (parentElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement);
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      // name of the file
      link.download = "Primas-chatbox-QR";
      link.click();
    }
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,");
    // hold the content type
    const imageType = parts[0].split(":")[1];
    // decode base64 string
    const decodedData = window.atob(parts[1]);
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }

  copy(content: string) {
    this.clipboard.copy(content);
    this.toastrService.show("Copied to Clipboard", "Notification", {
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      status: "success",
    });
  }

  dismiss() {
    this.ref.close();
  }
}
