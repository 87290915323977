<div id="graphContainer"></div>
<div id="outline" [ngClass]="{ 'show-map': showMap }"></div>
<div id="graphtoolbox">
  <div class="toolboxparent">
    <div class="toolbox">
      <nb-actions
        size="small"
        nbTooltip="List Variable"
        nbTooltipPlacement="top"
      >
        <nb-action
          [ngClass]="{ 'more-active': isShowVariable && popover.isShown }"
          [nbPopover]="attributeBox"
          nbPopoverTrigger="click"
          (click)="toolboxExcute('listVariable')"
          icon="listVariable"
        ></nb-action>
      </nb-actions>
    </div>
    <div class="toolbox">
      <nb-actions
        size="small"
        nbTooltip="Search Action"
        nbTooltipPlacement="top"
      >
        <nb-action
          [ngClass]="{ 'more-active': isShowActionName && popover.isShown }"
          nbPopoverTrigger="click"
          [nbPopover]="actionNameCallFlow"
          (click)="toolboxExcute('search')"
          icon="search"
        ></nb-action>
      </nb-actions>
    </div>

    <div class="toolbox">
      <nb-actions size="small">
        <nb-action
          nbTooltip="Interactive Mode"
          nbTooltipPlacement="top"
          [nbPopover]="interactiveTouchpad"
        >
          <i
            class="bi bi-mouse"
            style="color: #384cec; font-size: large"
            *ngIf="!interactiveMode"
          ></i>
          <i
            class="bi bi-laptop"
            style="color: #384cec; font-size: large"
            *ngIf="interactiveMode"
          ></i>
          &nbsp;&nbsp;
          <i
            class="bi bi-chevron-down"
            style="color: #384cec; font-size: medium"
          ></i>
        </nb-action>
        <nb-action
          nbTooltip="Undo"
          nbTooltipPlacement="top"
          (click)="toolboxExcute('undo')"
          icon="undo"
        ></nb-action>
        <nb-action
          nbTooltip="Redo"
          nbTooltipPlacement="top"
          (click)="toolboxExcute('redo')"
          icon="redo"
        ></nb-action>
        <nb-action
          nbTooltip="Shortcuts"
          nbTooltipPlacement="top"
          (click)="toolboxExcute('keyboard')"
          icon="keyboard"
        ></nb-action>
        <nb-action
          [ngClass]="{ 'more-active': showMap }"
          nbTooltip="Mini Map"
          nbTooltipPlacement="top"
          (click)="toolboxExcute('navigate')"
          icon="navigate"
        ></nb-action>

        <nb-action
          nbTooltip="Zoom In"
          nbTooltipPlacement="top"
          (click)="toolboxExcute('zoomIn')"
          icon="zoomIn"
          [ngClass]="{
            'disable-action': graph.getView().getScale() > maxZoomIn
          }"
        ></nb-action>
        <nb-action
          nbTooltip="Zoom Out"
          nbTooltipPlacement="top"
          (click)="toolboxExcute('zoomOut')"
          icon="zoomOut"
          [ngClass]="{
            'disable-action': graph.getView().getScale() < maxZoomOut
          }"
        ></nb-action>
        <nb-action
          nbTooltip="Actual Size"
          nbTooltipPlacement="top"
          (click)="toolboxExcute('enLarge')"
          icon="enLarge"
        ></nb-action>
      </nb-actions>
    </div>
  </div>
</div>

<ng-template #interactiveTouchpad>
  <div class="p-3">
    <nb-card-header class="p-0" style="border: 0">INTERACTIVE</nb-card-header>
    <nb-card-body
      class="row p-0"
      style="
        max-width: 450px;
        text-align: center;
        padding-top: 0.5rem !important;
      "
    >
      <div class="col-6" style="padding-right: 0">
        <nb-card
          class="interactive-card py-3"
          [ngClass]="{ 'interactive-mode': interactiveMode === false }"
          style="min-height: 160px; margin-bottom: 0px"
        >
          <nb-card-body (click)="changeInteractiveMode(false)">
            <i class="bi bi-mouse mt-3" style="font-size: 36px"></i>
            <p class="mb-0 mt-3" style="font-size: 13px; font-weight: bold">
              Mouse Mode
            </p>
            <p class="mb-0" style="font-size: 12px">
              Right-click to drag the canvas and zoom
            </p>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-6">
        <nb-card
          class="interactive-card py-3"
          style="min-height: 160px; margin-bottom: 0px"
          [ngClass]="{ 'interactive-mode': interactiveMode === true }"
        >
          <nb-card-body (click)="changeInteractiveMode(true)">
            <i class="bi bi-laptop mt-3" style="font-size: 36px"></i>
            <p class="mb-0 mt-3" style="font-size: 13px; font-weight: bold">
              Touchpad Mode
            </p>
            <p class="mb-0" style="font-size: 12px">
              Drag with two fingers and zoom
            </p>
          </nb-card-body>
        </nb-card>
      </div>
    </nb-card-body>
  </div>
</ng-template>

<div id="sipphone">
  <!-- <ngx-soft-phone
    [isCallflowDesign]="true"
    [ngStyle]="{ display: showCallBtn == false ? 'inline-grid' : 'none' }"
    (handlerCallControl)="callControlEvent($event)"
  ></ngx-soft-phone> -->
  <!-- <button
    [ngStyle]="{ display: showCallBtn == true ? 'inline-grid' : 'none' }"
    (click)="callControlEvent(showCallBtn)"
    class="float btn-float"
    nbButton
    ghost
    size="medium"
    style="background-color: white"
    status="primary"
  >
    <nb-icon icon="phone"></nb-icon>
  </button> -->
</div>

<ng-template #actionNameCallFlow>
  <nb-card
    style="
      max-height: 50vh;
      min-width: 200px;
      overflow-y: auto !important;
      margin: 10px;
      margin-top: 0;
      border: none;
    "
    size="large"
    *ngIf="isAllowDesign"
  >
    <nb-card-header
      style="
        padding: 10px 10px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
    >
      <span>List Action</span>
    </nb-card-header>
    <nb-card-body class="mb-2 mt-2" style="padding: 0 !important">
      <input
        type="text"
        nbInput
        [(ngModel)]="searchText"
        fullWidth
        (keyup)="filterAction()"
        placeholder="Search Action"
        fieldSize="small"
        class="mb-2"
      />
      <nb-list>
        <nb-list-item
          style="padding: 0.5rem !important"
          (click)="clickAction(item)"
          [class.active]="item == selectedItem"
          class="list-group-item-action"
          *ngFor="let item of actionList"
        >
          {{ item.value.attributes["name"].nodeValue }}
        </nb-list-item>
      </nb-list>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #attributeBox>
  <app-ivr-attribute
    [isReadOnly]="isReadOnly"
    [isAllowDesign]="isAllowDesign"
  ></app-ivr-attribute>
</ng-template>
<ng-template #ActionHeaderTemplate>
  <div class="header1">
    <div class="" *ngIf="isEditActionName">
      <input
        #actionInput
        type="text"
        style="width: 250px"
        [(ngModel)]="actionName"
        nbInput
        fullWidth
        fieldSize="small"
        id="actionName"
        placeholder="Action Name"
        [status]="errorActionName ? 'danger' : 'basic'"
        (blur)="handleInputBlur()"
        (ngModelChange)="checkActionName($event)"
        (keyup.enter)="handleInputBlur()"
      />
      <p
        class="caption status-danger"
        style="margin-bottom: -7px"
        *ngIf="errorActionName"
      >
        Maximum length is 35 characters.
      </p>
    </div>
    <div *ngIf="!isEditActionName" class="custom-header">
      <div>
        <span>{{ actionName }}</span>
        <button
          style="margin-left: 0.5rem"
          type="button"
          ghost
          size="small"
          (click)="editActionName(true)"
          nbButton
        >
          <nb-icon icon="edit-2-outline"></nb-icon>
        </button>
      </div>
      <button
        class="alert-circle-outline"
        type="button"
        ghost
        size="medium"
        nbButton
        nbPopoverPlacement="left"
        nbPopoverTrigger="hover"
        [nbPopover]="actionDes"
      >
        <nb-icon icon="question-mark-circle-outline"></nb-icon>
      </button>
      <button
        class="close-button"
        type="button"
        ghost
        size="medium"
        (click)="closeActionName(true)"
        nbButton
      >
        <nb-icon icon="close-outline"></nb-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #actionDes>
  <nb-card
    style="
      box-sizing: border-box;
      background-color: rgb(255, 255, 255);
      border-style: solid;
      border-width: 1px;
      border-color: rgb(225, 227, 234);
      box-shadow: rgba(18, 28, 45, 0.1) 0px 2px 8px 0px;
      max-width: 500px;
      min-width: 500px;
      height: auto;
      padding: 1.5rem 2rem;
      margin: -1px;
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      align-items: start;
    "
    size="large"
  >
    <div style="font-size: 16px; font-weight: 600">
      {{ callFlowActionDes.name }}
    </div>
    <span class="description" style="font-size: 14px">
      {{ callFlowActionDes.description }}
    </span>
    <div>
      <a
        class="viewMore"
        (click)="viewMore(callFlowActionDes)"
        style="font-size: 14px; text-decoration: none"
      >
        Learn more
      </a>
    </div>
  </nb-card>
</ng-template>
<div
  class="chat-popup-button"
  [ngStyle]="{
    display: dataService.openChatButton == false ? 'none' : 'flex'
  }"
  style="bottom: 80px; right: 24px; z-index: 5"
>
  <nb-card
    style="margin-bottom: 0"
    class="chat-popup-content"
    [ngClass]="{
      'open-chatbot-content': dataService.openChatContent == true
    }"
  >
    <nb-card-body style="padding: 0">
      <iframe
        id="primas-bot-portal-design"
        class="chat-content-popup"
        [src]="link | urliframe"
        frameborder="0"
      ></iframe>
    </nb-card-body>
  </nb-card>
  <button
    nbButton
    status="primary"
    shape="round"
    (click)="openChat()"
    class="chat-popup-button-show"
    [ngClass]="{ 'open-chatbot': dataService.openChatButton == true }"
  >
    <nb-icon
      [icon]="
        dataService.openChatContent ? 'close-outline' : 'question-mark-circle'
      "
    ></nb-icon>
  </button>
</div>
