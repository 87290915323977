<div style="display: flex">
  <nb-card style="width: 600px; height: 100vh" status="basic">
    <nb-card-header class="infor-header">
      <div class="d-flex justify-content-between">
        Set Up Chat Plugin
        <button (click)="dismiss()" type="button" class="btn-close" aria-label="Close"></button>
      </div>
    </nb-card-header>
    <nb-card-body style="margin-bottom: 1rem">
      <form [formGroup]="form" class="container-item">
        <div class="item-infor-col" style="width: 100%">
          <span class="name-label">Prominence</span>
          <span class="description-label">Choose how prominence you want Chat plugin to be on your website.</span>
          <div class="mb-2" style="width: 100%">
            <label for="btnname" class="form-label label">Show Mode</label>
            <div class="input-wrapper">
              <ng-select
                [items]="prominence"
                formControlName="show"
                [(ngModel)]="show"
                bindLabel="name"
                bindValue="value"
                [searchable]="false"
                [clearable]="false"
                (ngModelChange)="update()">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div style="display: flex; flex-direction: column">
                    <span>{{ item.name }}</span>
                    <div style="font-size: 11px">{{ item.des }}</div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="mb-2" style="width: 100%">
            <label for="btnname" class="form-label label">Trigger Mode</label>
            <div class="input-wrapper">
              <ng-select
                [items]="triggers"
                formControlName="trigger"
                [(ngModel)]="trigger"
                bindLabel="name"
                bindValue="value"
                [searchable]="false"
                [clearable]="false"
                (ngModelChange)="update()">
                <ng-template ng-option-tmp let-item="item" let-index="index" q let-search="searchTerm">
                  <div style="display: flex; flex-direction: column">
                    <span>{{ item.name }}</span>
                    <div style="font-size: 11px">{{ item.des }}</div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="mb-2" style="width: 100%">
            <label for="btnname" class="form-label label">Restart Mode</label>
            <div class="input-wrapper">
              <ng-select
                [items]="restarts"
                formControlName="trigger"
                [(ngModel)]="restart"
                bindLabel="name"
                bindValue="value"
                [searchable]="false"
                [clearable]="false"
                (ngModelChange)="update()">
                <ng-template ng-option-tmp let-item="item" let-index="index" q let-search="searchTerm">
                  <div style="display: flex; flex-direction: column">
                    <span>{{ item.name }}</span>
                    <div style="font-size: 11px">{{ item.des }}</div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="item-border"></div>
        <div class="item-infor-col" style="width: 100%">
          <span class="name-label">Custom</span>
          <span class="description-label">Change the name,logo and greeting of the Chat Plugin.</span>
          <div class="mb-2" style="width: 100%">
            <label for="btnname" class="form-label label">Bot Name</label>
            <div class="input-wrapper">
              <input
                id="btnname"
                type="text"
                fieldSize="small"
                nbInput
                fullWidth
                [(ngModel)]="name"
                formControlName="name"
                (ngModelChange)="update()"
                placeholder="Bot Name"
                [status]="
                  form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)
                    ? 'danger'
                    : 'basic'
                " />
            </div>
          </div>
          <div class="mb-2" style="width: 100%">
            <label for="btnlogo" class="form-label label">Logo URL</label>
            <div class="input-wrapper">
              <input
                id="btnlogo"
                type="text"
                fieldSize="small"
                nbInput
                fullWidth
                [(ngModel)]="logo"
                formControlName="logo"
                (ngModelChange)="update()"
                placeholder="Logo URL"
                [status]="
                  form.controls['logo'].invalid && (form.controls['logo'].dirty || form.controls['logo'].touched)
                    ? 'danger'
                    : 'basic'
                " />
            </div>
          </div>
          <div class="mb-2" style="width: 100%">
            <label for="btnG" class="form-label label">Greeting Messages</label>
            <div class="input-wrapper">
              <textarea
                id="btnG"
                type="text"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Greeting Messages"
                formControlName="greeting"
                style="min-height: 75px; max-height: 125px"
                [(ngModel)]="greeting"
                (ngModelChange)="update()"
                [status]="
                  form.controls['greeting'].invalid && (form.controls['greeting'].dirty || form.controls['greeting'].touched)
                    ? 'danger'
                    : 'basic'
                "></textarea>
            </div>
          </div>
        </div>
        <div class="item-border"></div>
        <div class="item-infor-col" style="width: 100%">
          <span class="name-label">Color</span>
          <span class="description-label">Change the color of the Chat Plugin.</span>
          <div class="mb-2" style="width: 100%">
            <div class="input-wrapper" style="position: relative">
              <input
                nbInput
                fullWidth
                [(colorPicker)]="color"
                readonly
                style="padding-left: 2.5rem"
                formControlName="color"
                [(ngModel)]="color"
                (ngModelChange)="update()" />
              <div [style.background]="color" class="color-preview"></div>
            </div>
          </div>
        </div>
        <div class="item-border"></div>
        <div class="item-infor-col" style="width: 100%">
          <span class="name-label">Chat Button</span>
          <span class="description-label">Customize the chat button style</span>
          <div class="mb-2" style="width: 100%">
            <label for="btnSize" class="form-label label">Button Size</label>
            <div class="input-wrapper">
              <input
                id="btnSize"
                type="text"
                fieldSize="small"
                nbInput
                [(ngModel)]="icon"
                fullWidth
                placeholder="Button Size"
                formControlName="icon"
                (ngModelChange)="update()"
                [status]="
                  form.controls['icon'].invalid && (form.controls['icon'].dirty || form.controls['icon'].touched)
                    ? 'danger'
                    : 'basic'
                " />
              <span>px</span>
            </div>
            <ng-container
              *ngIf="form.controls['icon'].invalid && (form.controls['icon'].dirty || form.controls['icon'].touched)">
              <p class="caption status-danger" *ngIf="form.controls['icon'].errors.required" style="margin-bottom: 0">
                Button Size is required.
              </p>
              <p
                class="caption status-danger"
                *ngIf="form.controls['icon'].errors.maxLength && !form.controls['icon'].errors.digit"
                style="margin-bottom: 0">
                Maximum length is 3 characters.
              </p>
              <p class="caption status-danger" *ngIf="form.controls['icon'].errors.digit" style="margin-bottom: 0">
                Button Size must contain only numbers.
              </p>
            </ng-container>
          </div>
          <div class="mb-2" style="width: 100%" class="d-flex flex-column">
            <label for="btnSize" class="form-label label">Button Icon</label>
            <nb-button-group (valueChange)="updateSelectedChatIcon($event)" class="d-flex gap-4">
              <button
                nbButtonToggle
                class="rounded-circle"
                status="primary"
                value="1"
                style="width: 50px; height: 50px"
                pressed>
                <i class="bi bi-chat"></i>
              </button>
              <button nbButtonToggle class="rounded-circle" status="primary" value="2" style="width: 50px; height: 50px">
                <i class="bi bi-envelope"></i>
              </button>
              <button nbButtonToggle class="rounded-circle" status="primary" value="3" style="width: 50px; height: 50px">
                <i class="bi bi-info-circle-fill"></i>
              </button>
              <button nbButtonToggle class="rounded-circle" status="primary" value="4" style="width: 50px; height: 50px">
                <i class="bi bi-send"></i>
              </button>
            </nb-button-group>
          </div>
          <div class="mb-2" style="width: 100%" class="d-flex flex-column">
            <label for="btnSize" class="form-label label">Button Shape</label>
            <nb-button-group (valueChange)="updateSelectedChatShape($event)" class="d-flex gap-4">
              <button
                nbButtonToggle
                class="rounded-circle"
                status="primary"
                style="width: 50px; height: 50px"
                value="1"
                pressed></button>
              <button nbButtonToggle class="rounded" status="primary" value="2" style="width: 50px; height: 50px"></button>
              <button
                nbButtonToggle
                class="rounded-bottom-circle rounded-start-circle"
                status="primary"
                value="3"
                style="width: 50px; height: 50px"></button>
              <button
                nbButtonToggle
                class="rounded-top-circle rounded-start-circle"
                status="primary"
                value="4"
                style="width: 50px; height: 50px"></button>
            </nb-button-group>
          </div>
          <div class="mb-2" style="width: 100%" class="d-flex flex-column">
            <label for="btnSize" class="form-label label">Button Shape</label>
            <nb-button-group (valueChange)="updateSelectedChatMinimize($event)" class="d-flex gap-4">
              <button
                nbButtonToggle
                class="rounded-circle"
                status="primary"
                style="width: 50px; height: 50px"
                value="1"
                pressed>
                <i class="bi bi-x-lg"></i>
              </button>
              <button nbButtonToggle class="rounded-circle" status="primary" value="2" style="width: 50px; height: 50px">
                <i class="bi bi-chevron-down"></i>
              </button>
              <button nbButtonToggle class="rounded-circle" status="primary" value="3" style="width: 50px; height: 50px">
                <i class="bi bi-arrow-down"></i>
              </button>
              <button nbButtonToggle class="rounded-circle" status="primary" value="4" style="width: 50px; height: 50px">
                <i class="bi bi-arrows-angle-contract"></i>
              </button>
            </nb-button-group>
          </div>
        </div>
        <div class="item-border"></div>
        <div class="item-infor-col" style="width: 100%">
          <span class="name-label">Chat Window Size</span>
          <span class="description-label">Regulate the Chat Plugin's dimensions on your website</span>
          <div class="mb-2" style="width: 100%">
            <label for="btnWidth" class="form-label label">Width</label>
            <div class="input-wrapper">
              <input
                id="btnWidth"
                type="text"
                fieldSize="small"
                nbInput
                fullWidth
                [(ngModel)]="width"
                formControlName="width"
                (ngModelChange)="update()"
                placeholder="Width"
                [status]="
                  form.controls['width'].invalid && (form.controls['width'].dirty || form.controls['width'].touched)
                    ? 'danger'
                    : 'basic'
                " />
              <span>px</span>
            </div>
            <ng-container
              *ngIf="form.controls['width'].invalid && (form.controls['width'].dirty || form.controls['width'].touched)">
              <p class="caption status-danger" *ngIf="form.controls['width'].errors.required" style="margin-bottom: 0">
                Width is required.
              </p>
              <p
                class="caption status-danger"
                *ngIf="form.controls['width'].errors.maxLength && !form.controls['width'].errors.digit"
                style="margin-bottom: 0">
                Maximum length is 3 characters.
              </p>
              <p class="caption status-danger" *ngIf="form.controls['width'].errors.digit" style="margin-bottom: 0">
                Width must contain only numbers.
              </p>
            </ng-container>
          </div>
          <div class="mb-2" style="width: 100%">
            <label for="btnHeight" class="form-label label">Height</label>
            <div class="input-wrapper">
              <input
                id="btnHeight"
                type="number"
                min="1"
                max="100"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Height"
                formControlName="height"
                [(ngModel)]="height"
                (ngModelChange)="update()"
                [status]="
                  form.controls['height'].invalid && (form.controls['height'].dirty || form.controls['height'].touched)
                    ? 'danger'
                    : 'basic'
                " />
              <span>%</span>
            </div>
            <ng-container
              *ngIf="form.controls['height'].invalid && (form.controls['height'].dirty || form.controls['height'].touched)">
              <p class="caption status-danger" *ngIf="form.controls['height'].errors.required" style="margin-bottom: 0">
                Height is required.
              </p>
              <p
                class="caption status-danger"
                *ngIf="
                  form.controls['height'].errors &&
                  !form.controls['height'].errors.digit &&
                  !form.controls['height'].errors.required
                "
                style="margin-bottom: 0">
                The range value from 1% to 100%.
              </p>
              <p class="caption status-danger" *ngIf="form.controls['height'].errors.digit" style="margin-bottom: 0">
                Height must contain only numbers.
              </p>
            </ng-container>
          </div>
        </div>
        <div class="item-border"></div>
        <div class="item-infor-col" style="width: 100%">
          <span class="name-label">Position</span>
          <span class="description-label">Determine the Chat Plugins positioning on your website.</span>
          <div class="mb-2" style="width: 100%">
            <label for="alignment" class="form-label label">Alignment</label>
            <div class="input-wrapper">
              <ng-select
                [items]="siteArray"
                formControlName="site"
                [(ngModel)]="site"
                bindLabel="name"
                bindValue="value"
                [searchable]="false"
                [clearable]="false"
                (ngModelChange)="update()">
              </ng-select>
            </div>
          </div>
          <div class="mb-2" style="width: 100%">
            <label for="posA" class="form-label label">Bottom Margin</label>
            <div class="input-wrapper">
              <input
                id="posA"
                type="text"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Bottom Margin"
                formControlName="posA"
                [(ngModel)]="posA"
                (ngModelChange)="update()"
                [status]="
                  form.controls['posA'].invalid && (form.controls['posA'].dirty || form.controls['posA'].touched)
                    ? 'danger'
                    : 'basic'
                " />
              <span>px</span>
            </div>
            <ng-container
              *ngIf="form.controls['posA'].invalid && (form.controls['posA'].dirty || form.controls['posA'].touched)">
              <p class="caption status-danger" *ngIf="form.controls['posA'].errors.required" style="margin-bottom: 0">
                Bottom Margin is required.
              </p>
              <p
                class="caption status-danger"
                *ngIf="form.controls['posA'].errors.maxLength && !form.controls['posA'].errors.digit"
                style="margin-bottom: 0">
                Maximum length is 3 characters.
              </p>
              <p class="caption status-danger" *ngIf="form.controls['posA'].errors.digit" style="margin-bottom: 0">
                Bottom Margin must contain only numbers.
              </p>
            </ng-container>
          </div>
          <div class="mb-2" style="width: 100%">
            <label for="posB" class="form-label label">Right Margin</label>
            <div class="input-wrapper">
              <input
                id="posB"
                type="text"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Right Margin"
                formControlName="posB"
                [(ngModel)]="posB"
                (ngModelChange)="update()"
                [status]="
                  form.controls['posB'].invalid && (form.controls['posB'].dirty || form.controls['posB'].touched)
                    ? 'danger'
                    : 'basic'
                " />
              <span>px</span>
            </div>
            <ng-container
              *ngIf="form.controls['posB'].invalid && (form.controls['posB'].dirty || form.controls['posB'].touched)">
              <p class="caption status-danger" *ngIf="form.controls['posB'].errors.required" style="margin-bottom: 0">
                Right Margin is required.
              </p>
              <p
                class="caption status-danger"
                *ngIf="form.controls['posB'].errors.maxLength && !form.controls['posB'].errors.digit"
                style="margin-bottom: 0">
                Maximum length is 3 characters.
              </p>
              <p class="caption status-danger" *ngIf="form.controls['posB'].errors.digit" style="margin-bottom: 0">
                Right Margin must contain only numbers.
              </p>
            </ng-container>
          </div>
        </div>
      </form>
    </nb-card-body>
    <nb-card-footer style="display: flex; align-items: center; justify-content: space-between; gap: 0.5rem">
      <button
        type="button"
        nbButton
        status="primary"
        outline
        size="small"
        [nbPopover]="templateRef"
        nbPopoverTrigger="noop"
        nbPopoverPlacement="left"
        (click)="showPopover()">
        <nb-icon icon="eye-outline"></nb-icon>
        Previews
      </button>
      <div style="display: flex; align-items: center; justify-content: space-between; gap: 0.5rem">
        <button type="button" nbButton status="basic" size="small" (click)="reset()">Reset</button>
        <button [disabled]="!form.valid" type="button" nbButton status="primary" size="small" (click)="done()">
          Generate
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>
<ng-template #templateRef>
  <nb-card style="margin-bottom: 0 !important; width: 500px; height: 400px">
    <nb-card-header class="infor-header-2">
      <nb-user onlyPicture [picture]="logo?.trim() || logoDefault"> </nb-user>
      <span class="botname">{{ name?.trim() || dataCallFlow.callSettings[0].botName || dataCallFlow.name }}</span>
    </nb-card-header>
    <nb-card-body>
      <div class="chat-area" *ngIf="greeting && greeting?.trim() != ''">
        <nb-user style="transform: scale(0.8)" onlyPicture [picture]="logo?.trim() || logoDefault"> </nb-user>
        <div class="chat-box">
          <span class="chat-name">{{ name?.trim() || dataCallFlow.callSettings[0].botName || dataCallFlow.name }}</span>
          <span class="chat-text">{{ greeting?.trim() }}</span>
        </div>
      </div>
      <div class="chat-area chat-area-user">
        <div class="chat-box chat-box-user">
          <span class="chat-name">You</span>
          <span class="chat-text-user" [style.background]="color">I want to make an appointment today</span>
        </div>
      </div>
      <div class="chat-area">
        <nb-user style="transform: scale(0.8)" onlyPicture [picture]="logo?.trim() || logoDefault"> </nb-user>
        <div class="chat-box">
          <span class="chat-name">{{ name?.trim() || dataCallFlow.callSettings[0].botName || dataCallFlow.name }}</span>
          <span class="chat-text"
            >Certainly, I'm ready to help you schedule an appointment today. What time are you looking for?</span
          >
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
