import { SetAttribute } from "./../@core/model/set-attribute";
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import {
  NbToastrService,
  NbGlobalPhysicalPosition,
  NbDialogService,
  NbSidebarService,
  NbWindowRef,
  NbPopoverDirective,
} from "@nebular/theme";
import { CallFlowsGraphComponent } from "./design/callflows-graph.component";
import { ActivatedRoute, Router } from "@angular/router";
import { EventBusService } from "../@core/utils/eventbus.service";
import { InstructionService } from "../@core/utils/instruction.service";
import {
  CallFlowDto,
  CallFlowClient,
  ImportCallFlowCommand,
  ExportCallFlowCommand,
  MakeTestCallCommand,
  UpdateCallFlowCommand,
  CreateCallFlowCommand,
  DraftClient,
  UpdateDraftCommand,
  DraftDto,
  PublishDraftCommand,
  CreateDraftCommand,
  CallSettingDto,
  NumberClient,
  AssignCallFlowCommand,
  UserActionClient,
  CheckCallFlowNameCommand,
  CheckFileImportCallFlowCommand,
  ServiceTranslate,
  RequestModificationFlowCommand,
  CallSettingClient,
  DuplicateCallFlowCommand,
  AgentClient,
  GenerateLinkTestingCommand,
} from "../System-api";
import { DataService } from "../@core/utils/data.service";
import { ChooseTestNumberComponent } from "./actions/other/choose-test-number/choose-test-number.component";
import { RevisionHistoryComponent } from "./actions/other/revision-history/revision-history.component";
import { GraphHandlerService } from "../@core/utils/graph.service";
import {
  MxAutoSaveManager,
  MxGraph,
  MXUTILITIES,
} from "../@core/model/mxgraph";
import { Subscription } from "rxjs";
import { SettingCallFlowsComponent } from "./actions/other/setting-callflows/setting-callflows.component";
import { ConfirmDialogComponent } from "../shared/confirm-dialog/confirm-dialog.component";
import { AlertDialogComponent } from "../shared/alert-dialog/alert-dialog.component";
import { TraceLogData } from "../@core/model/trace-log";
import { WaitAction } from "../@core/model/wait-action";
import { OutreachAction } from "../@core/model/outreach-action";
import { TranslateAction } from "./../@core/model/translate-action";
import { RedirectCallflow } from "../@core/model/redirectcallflow";
import { HttpRequestData } from "../@core/model/http-request";
import { CheckTime } from "../@core/model/check-time";
import { CustomFunction } from "../@core/model/custom-function";
import { CustomEvent } from "../@core/model/custom-event";
import { CheckAttribute } from "../@core/model/check-attribute";
import { SetLanguage } from "../@core/model/set-language";
import { GetInput } from "../@core/model/get-input";
import { VoiceMail } from "../@core/model/voicemail";
import { Transfer } from "../@core/model/transfer";
import { GoToQueue } from "../@core/model/go-to-queue";
import { PlayAudioData } from "../@core/model/playaudio";
import { MenuAppComponent } from "./design/menu-app/menu-app.component";
import { ChatComponent } from "../portal/chat/chat.component";
import { LogModalComponent } from "./actions/other/log-modal/log-modal.component";
import { saveAs } from "file-saver";
import { DataSet } from "../@core/model/dataset";
import { ChatGPT } from "../@core/model/chatgpt";
import { Dial } from "../@core/model/dial";
import { HangUpAction } from "../@core/model/hangup-action";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import {
  generateRandomString,
  noWhitespaceValidator,
  decryptParams,
} from "../@core/utils/helpers";
import { ImportFlowModalComponent } from "./actions/other/import-flow-modal/import-flow-modal.component";
import { SignalRService } from "../@core/utils/signalR.service";
import { RequestModificationDialogComponent } from "./actions/other/request-modification/request-modification.component";
import { HttpClient } from "@angular/common/http";
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
} from "date-fns";
import { AnswerDetect } from "../@core/model/answer-detect";
import { Forms } from "../@core/model/forms";
import { IntentRecognition } from "../@core/model/intent-recognition";
import { SendMail } from "../@core/model/send-mail";
import { CMS } from "../@core/model/cms";
import { LLM } from "../@core/model/llm";
import { documentation } from "../../environments/environment";
import { ImportFlowResultComponent } from "./actions/other/import-flow-result/import-flow-result.component";
import { Cards } from "../@core/model/cards";
import { DebugModalComponent } from "../shared/debug-modal/debug-modal.component";
import { EventData } from "../shared/model/eventdata";
import { IntentRouting } from "../@core/model/intent-routing";
import { MultipleConditions } from "../@core/model/multiple-conditions";
import { BuiltInFunction } from "../@core/model/built-in-function";
import { GenerateQRComponent } from "../shared/generate-qr/generate-qr.component";
declare var WebPhone: any;

@Component({
  selector: "callflows-design",
  templateUrl: "callflows-design.component.html",
  styleUrls: ["./callflows-design.component.scss"],
})
export class CallflowsDesignComponent implements OnInit, AfterViewInit {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild("inputName") inputName: ElementRef<any>;
  @ViewChild(CallFlowsGraphComponent, { static: false })
  private callflowGraph: CallFlowsGraphComponent;
  @ViewChild(MenuAppComponent, { static: false })
  menuAction: MenuAppComponent;
  @ViewChild(ChatComponent, { static: false })
  chatComponent: ChatComponent;
  @ViewChild("scriptWebphone", { static: true }) scriptWebphone!: ElementRef;

  webPhoneInstance: any;

  errorRegex =
    'Flow Name cannot contain any of the following characters: \\ / : *? " < > |';
  @HostListener("window:popstate", ["$event"])
  onBrowserBack($event: any) {
    if (this.isReadOnly != true) {
      this.callFlowClient.outCallFlow(this.data.id).subscribe((result) => {});
    }
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadHandler($event: any) {
    if (this.isReadOnly != true && this.isChanged) {
      if (
        this.checkGraphChanged(this.draft, this.callflowGraph, this.dataService)
      ) {
        $event.returnValue = true;
      }
    }
  }

  @HostListener("window:keydown", ["$event"])
  handleKeyDownEvent(event: KeyboardEvent): void {
    const allowedKeys = [
      "Space",
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
    ];
    if (
      event.target instanceof HTMLBodyElement &&
      allowedKeys.includes(event.code)
    ) {
      event.preventDefault();
    }
  }
  appItems: any = [];
  pipe = new DatePipe("en-US");
  data: CallFlowDto = new CallFlowDto();
  isChanged: boolean = false;
  draft: DraftDto = null;
  fileUrl;
  fileFlow;
  isCollapsed = true;
  isSetting: boolean = false;
  graph: MxGraph;
  autoSaveManager: MxAutoSaveManager;
  windowRef: NbWindowRef;
  timeout: any = null;
  windowSubscription: Subscription;
  isAutoSave: boolean = true;
  isDesignShow: boolean = false;
  isAllowDesign: boolean = false;
  subscription: Subscription;
  loadAction: boolean = false;
  showChatBtn: boolean = true;
  isExist: boolean = false;
  currentName: string;
  callFlowNameForm: UntypedFormGroup;
  callFlowTypeForm: UntypedFormGroup;
  isNameLoading: boolean = false;
  isSaveLoading: boolean = false;
  isPublishLoading: boolean = false;
  errorMessage: string = "";
  defaultMedia = [];
  changedDate: Date;
  savedStatus: string = "";
  dataImport: any = {
    chatgpt: [],
    grammar: [],
    function: [],
    audios: [],
  };
  firstChange = false;
  isLoading = false;
  requestPermissionStatusSubscription: Subscription;
  checkTime = null;
  callSettingImport = false;
  isReadOnly: any = null;
  linkDocumentation = documentation;
  chatId = generateRandomString(10);
  constructor(
    private sidebarService: NbSidebarService,
    private dataService: DataService,
    private eventBusService: EventBusService,
    private introService: InstructionService,
    private callFlowClient: CallFlowClient,
    private userActionClient: UserActionClient,
    private draftClient: DraftClient,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private actr: ActivatedRoute,
    private graphHandler: GraphHandlerService,
    private router: Router,
    private numberClient: NumberClient,
    private formBuilder: RxFormBuilder,
    private signalRService: SignalRService,
    private http: HttpClient,
    private callSettingClient: CallSettingClient,
    public agentClient: AgentClient
  ) {
    this.createForm();
    this.getAction();
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.eventBusService.on("getMxGraph", (graph: MxGraph) => {
      this.graph = graph;
    });

    this.eventBusService.on("MxGraphChanges", (graph: MxGraph) => {
      this.graph = graph;
      if (this.firstChange == false) {
        this.isChanged = true;
        this.firstChange = true;
      }
      if (this.graph) {
        this.graph
          .getModel()
          .addListener(MXUTILITIES.mxEvent.CHANGE, async (sender, event) => {
            this.isChanged = true;
            //this.autoSave();
          });
      }
    });

    this.sidebarService.onToggle().subscribe((tag) => {
      if (tag.tag == "menu-app-sidebar") {
        this.isCollapsed = !this.isCollapsed;
      }
    });
    this.eventBusService.on("clickRevertRevision", (data: DraftDto) => {
      if (data) {
        this.revertRevisionHandle(data);
        return;
      }
    });
    window.addEventListener("unload", () => {
      if (this.isReadOnly != true && this.isChanged) {
        if (
          this.draft?.id &&
          (this.callflowGraph.getXml() != this.draft.data ||
            this.callflowGraph.getFlow() != this.draft.jsonFormat)
        ) {
          this.callFlowClient
            .outCallFlow(this.data.id)
            .subscribe((result) => {});
        } else if (
          !this.draft?.id &&
          (this.callflowGraph.getXml() != this.dataService.CallFlow.data ||
            this.callflowGraph.getFlow() !=
              this.dataService.CallFlow.jsonFormat)
        ) {
          this.callFlowClient
            .outCallFlow(this.data.id)
            .subscribe((result) => {});
        }
      }
    });
    window.addEventListener("message", this.handleRestartConversation);
  }
  handleRestartConversation = (event) => {
    if (event.data.type === "RESTART_CONVO") {
      const callSetting = {
        ...this.dataService.CallSetting,
        companyId: this.dataService.CompanyId,
      };
      var callFlowJson = this.callflowGraph.getFlow();
      var callSettings = JSON.stringify(callSetting);
      var attribute = JSON.stringify(this.dataService.ivrAttribute);
      let sss = document.getElementById("primas-chat-frame") as any;
      sss.contentWindow.postMessage(
        {
          type: "RUN_FLOW",
          value: { callFlowJson, callSettings, attribute },
        },
        "*"
      );
      this.eventBusService.emit(new EventData("restartDebug", true));
    }
  };
  canDeactivate: () => boolean;
  createForm() {
    this.callFlowNameForm = this.formBuilder.group({
      name: [
        "",
        [
          RxwebValidators.required(),
          noWhitespaceValidator,
          Validators.maxLength(50),
          RxwebValidators.pattern({
            expression: {
              regex: /^[^\\\/:*?"<>|]+$/,
            },
          }),
        ],
      ],
    });
    this.callFlowTypeForm = this.formBuilder.group({
      callFlowType: [""],
    });
  }

  filterAction(event) {
    this.menuAction.filterAction(event);
  }

  ngAfterViewInit() {
    const script = document.createElement("script");
    script.id = "webphoneScript";
    script.src = "webphone/assets/js/webphone.js";
    script.type = "text/javascript";

    script.setAttribute("buttoncolor", "success");
    script.setAttribute("position", "right");
    script.setAttribute("bottom", "140px");

    script.onload = this.onScriptLoad.bind(this);
    this.scriptWebphone.nativeElement.appendChild(script);
  }

  onScriptLoad(): void {
    this.agentClient.getSipAccount().subscribe((response) => {
      if (response) {
        let userName = response.userName;
        let password = response.password;
        let showLogout = "false";

        let webPhoneCredential = localStorage.getItem("webphone_credential");
        if (webPhoneCredential && !userName && !password) {
          webPhoneCredential = decryptParams(
            webPhoneCredential.replace('"', ""),
            this.dataService.webPhoneSecretKey
          );
          let jsonwebPhoneCredential = JSON.parse(webPhoneCredential);
          userName = jsonwebPhoneCredential.userName;
          password = jsonwebPhoneCredential.password;
          showLogout = "true";
        }

        this.webPhoneInstance = new WebPhone();
        this.webPhoneInstance.login(
          window.location.hostname,
          response.sipPort,
          "wss",
          userName,
          password,
          showLogout
        );
      }
    });
  }

  doUnload() {
    if (this.isReadOnly != true) {
      this.callFlowClient.outCallFlow(this.data.id).subscribe((result) => {});
    }
  }
  async getAction() {
    this.appItems = {
      messaging: [
        {
          id: "PlayAudio",
          name: "Play Prompt",
          description: "",
          iconImage: "/assets/images/playaudio_50px.png",
          data: new PlayAudioData(),
        },
        {
          id: "GetInput",
          name: "Prompt & Collect",
          description: "",
          iconImage: "/assets/images/getinput_50px.png",
          data: new GetInput(),
        },
        {
          id: "Cards",
          name: "Cards",
          description: "",
          iconImage: "/assets/images/cards_50px.png",
          data: new Cards(),
        },
        {
          id: "Forms",
          name: "Forms",
          description: "",
          iconImage: "/assets/images/forms_50px.png",
          data: new Forms(),
        },
      ],
      transfers: [
        {
          id: "Dial",
          name: "Dial",
          description: "",
          iconImage: "/assets/images/dial_50px.png",
          data: new Dial(),
        },
        {
          id: "GoToQueue",
          name: "Go To Queue",
          description: "",
          iconImage: "/assets/images/gotoqueue_50px.png",
          data: new GoToQueue(),
        },
        {
          id: "Transfer",
          name: "Transfer",
          description: "",
          iconImage: "/assets/images/transfer_50px.png",
          data: new Transfer(),
        },
      ],
      variables: [
        {
          id: "BuiltInFunction",
          name: "Built-In Function",
          description: "",
          iconImage: "/assets/images/builtIn-function_50px.png",
          data: new BuiltInFunction(),
        },
        {
          id: "CheckAttribute",
          name: "Check Variable",
          description: "",
          iconImage: "/assets/images/checkattribute_50px.png",
          data: new CheckAttribute(),
        },
        {
          id: "MultipleConditions",
          name: "Multiple Conditions",
          description: "",
          iconImage: "/assets/images/multiple_conditions_50px.png",
          data: new MultipleConditions(),
        },
        {
          id: "SetLanguage",
          name: "Set Language",
          description: "",
          iconImage: "/assets/images/language_50px.png",
          data: new SetLanguage(),
        },
        {
          id: "SetAttribute",
          name: "Set Variable",
          description: "",
          iconImage: "/assets/images/setattribute_50px.png",
          data: new SetAttribute(),
        },
      ],
      ai: [
        {
          id: "IntentRecognition",
          name: "Intent Recognition",
          description: "",
          iconImage: "/assets/images/intent_50px.png",
          data: new IntentRecognition(),
        },
        {
          id: "IntentRouting",
          name: "Intent Routing",
          description: "",
          iconImage: "/assets/images/intent_routing_50px.png",
          data: new IntentRouting(),
        },
        {
          id: "LLM",
          name: "LLMs",
          description: "",
          iconImage: "/assets/images/llm_50px.png",
          data: new LLM(),
        },
        {
          id: "ChatGPT",
          name: "Virtual Agent",
          description: "",
          iconImage: "/assets/images/chatgpt_50px.png",
          data: new ChatGPT(),
        },
      ],
      custom: [
        {
          id: "CustomEvent",
          name: "Custom Event",
          description: "",
          iconImage: "/assets/images/Event_50px.png",
          data: new CustomEvent(),
        },
        {
          id: "CustomFunction",
          name: "Custom Function",
          description: "",
          iconImage: "/assets/images/function-fx-50px.png",
          data: new CustomFunction(),
        },
        {
          id: "HttpRequest",
          name: "Http Request",
          description: "",
          iconImage: "/assets/images/httprequest_50px.png",
          data: new HttpRequestData(),
        },
      ],
      data: [
        {
          id: "CMS",
          name: "Data Storages",
          description: "",
          iconImage: "/assets/images/cms_50px.png",
          data: new CMS(),
        },
        {
          id: "DataSet",
          name: "Report",
          description: "",
          iconImage: "/assets/images/Database_50px.png",
          data: new DataSet(),
        },
      ],
      flow: [
        {
          id: "AnswerDetect",
          name: "AMD",
          description: "",
          iconImage: "/assets/images/answer_detect.png",
          data: new AnswerDetect(),
        },
        {
          id: "CheckTime",
          name: "Check Time",
          description: "",
          iconImage: "/assets/images/checktime_50px.png",
          data: new CheckTime(),
        },
        {
          id: "HangupAction",
          name: "Hangup",
          description: "",
          iconImage: "/assets/images/hangup.png",
          data: new HangUpAction(),
        },
        {
          id: "Outreach",
          name: "Outreach Action",
          description: "",
          iconImage: "/assets/images/outreach_40px.png",
          data: new OutreachAction(),
        },
        {
          id: "SendMail",
          name: "Send Mail",
          description: "",
          iconImage: "/assets/images/send_mail_50px.png",
          data: new SendMail(),
        },
        {
          id: "RedirectCallflow",
          name: "Subflow",
          description: "",
          iconImage: "/assets/images/redirectcallflow_50px.png",
          data: new RedirectCallflow(),
        },
        {
          id: "TraceLog",
          name: "Trace Log",
          description: "",
          iconImage: "/assets/images/log_50px.png",
          data: new TraceLogData(),
        },
        {
          id: "Translate",
          name: "Translate",
          description: "",
          iconImage: "/assets/images/translate_50px.png",
          data: new TranslateAction(),
        },
        {
          id: "VoiceMail",
          name: "Record VoiceMail",
          description: "",
          iconImage: "/assets/images/voicemail_50px.png",
          data: new VoiceMail(),
        },
        {
          id: "WaitAction",
          name: "Wait Action",
          description: "",
          iconImage: "/assets/images/wait_50px.png",
          data: new WaitAction(),
        },
      ],
      user: [],
    };
    let dataReturn = await this.userActionClient.getAll().subscribe((rs) => {
      if (dataReturn) {
        this.dataService.userAction = [];
        rs.userActions.map((flow) => {
          const obj = {
            id: flow.applicationId + "-" + flow.id,
            name: flow.name,
            description: "",
            iconImage: flow.iconImage,
            data: JSON.parse(flow.data),
          };
          this.appItems.user.push(obj);
          this.dataService.userAction.push(obj);
        });
        this.loadAction = true;
      }
    });
  }
  ngOnDestroy(): void {
    this.signalRService.stopHubConnection();
    this.requestPermissionStatusSubscription.unsubscribe();
    this.introService.introJS.exit();
    this.dataService.ivrAttribute = null;
    this.draft = null;
    this.eventBusService.unsubcribe();
    this.dataService.CallSetting = null;
    this.dataService.CallFlow = null;
    clearInterval(this.checkTime);
    this.handleOverFlowRemoveBody();
    window.removeEventListener("message", this.handleRestartConversation);

    if (this.webPhoneInstance) {
      this.webPhoneInstance.discard();
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-app-sidebar");
    this.menuAction.showName = this.isCollapsed;
    return false;
  }
  ngOnInit() {
    if (this.dataService.isReadOnly) {
      this.isReadOnly = true;
      this.isAllowDesign = true;
      this.dataService.isReadOnly = null;
    }
    //subcription request modification flow
    this.requestPermissionStatusSubscription =
      this.signalRService.requestPermissionStatus.subscribe((rs) => {
        this.askModifyFlow(rs);
      });

    this.actr.params.subscribe((params) => {
      if (params["id"]) {
        if (this.dataService.CallFlow && this.dataService.CallSetting) {
          this.getNewestVersion(this.dataService.CallFlow);
        } else {
          this.callFlowClient.get(params["id"]).subscribe((getCallFlow) => {
            if (getCallFlow) {
              this.getNewestVersion(getCallFlow);
            } else {
              this.router.navigateByUrl("/page/404-not-found");
            }
          });
        }
      }
    });
    this.setDefaultSetting();
    this.setDefaultIvrFunction();
    this.signalRService.startConnection().then((rs) => {
      if (rs) {
        this.signalRService.requestPermisstionFlow(this.dataService.userName);
        this.signalRService.addUpdateStatusGrammarListener();
        this.signalRService.addUpdateStatusGPTListener();
      }
    });
    this.checkTime = setInterval(() => {
      this.checkDateChanged();
    }, 10000);
    this.handleOverFlowAddBody();
    if (this.callFlowNameForm.controls["name"].invalid) {
      this.callFlowNameForm.get("name").markAsDirty();
      this.callFlowNameForm.get("name").markAsTouched();
    }
  }
  setDefaultIvrFunction() {
    this.dataService.ivrFunction = [
      { value: "spell()" },
      { value: "sayDigits()" },
      { value: "sayNumber()" },
    ];
  }
  handleOverFlowAddBody() {
    var body = document.getElementsByTagName("BODY")[0];
    if (body) {
      body.classList.add("overflow-hidden-body");
    }
  }
  handleOverFlowRemoveBody() {
    var body = document.getElementsByTagName("BODY")[0];
    if (body) {
      body.classList.remove("overflow-hidden-body");
    }
  }
  checkDateChanged() {
    var dateNow = Date.now();
    if (this.changedDate) {
      if (differenceInMinutes(dateNow, this.changedDate) <= 0) {
        this.savedStatus = "Last save less than a minute ago";
      } else if (differenceInMinutes(dateNow, this.changedDate) < 60) {
        this.savedStatus = `Last save ${differenceInMinutes(
          dateNow,
          this.changedDate
        )} ${
          differenceInMinutes(dateNow, this.changedDate) == 1
            ? "minute"
            : "minutes"
        } ago`;
      } else if (differenceInHours(dateNow, this.changedDate) < 24) {
        this.savedStatus = `Last save ${differenceInHours(
          dateNow,
          this.changedDate
        )} ${
          differenceInHours(dateNow, this.changedDate) == 1 ? "hour" : "hours"
        } ago`;
      } else if (differenceInDays(dateNow, this.changedDate) < 30) {
        this.savedStatus = `Last save ${differenceInDays(
          dateNow,
          this.changedDate
        )} ${
          differenceInDays(dateNow, this.changedDate) == 1 ? "day" : "days"
        } ago`;
      } else if (differenceInMonths(dateNow, this.changedDate) <= 12) {
        this.savedStatus = `Last save ${differenceInMonths(
          dateNow,
          this.changedDate
        )} ${
          differenceInMonths(dateNow, this.changedDate) == 1
            ? "month"
            : "months"
        } ago`;
      } else if (
        differenceInMonths(dateNow, this.changedDate) > 12 &&
        differenceInMonths(dateNow, this.changedDate) <= 24
      ) {
        this.savedStatus = "Last save more than 1 year ago";
      } else {
        this.savedStatus = "Last save long time ago";
      }
    }
  }

  getNewestVersion(result) {
    this.draftClient.getNewestVersion(result.id).subscribe((draft) => {
      if (draft) {
        this.dataService.CallFlow = result;
        this.dataService.CallSetting = result.callSettings[0];
        result.data = draft.data;
        result.attribute = draft.attribute;
        result.jsonFormat = draft.jsonFormat;
        this.data = result;
        this.changedDate = draft.changedDate;
        this.checkDateChanged();
        this.setIvrAttribute();
        this.isDesignShow = true;
        if (this.isReadOnly != true) {
          this.checkCallFlowDesign(result.id, draft.name);
        }
        this.setWindowTitle(result.name);
        this.currentName = result.name;
        if (!this.dataService.CallSetting.media) {
          this.dataService.CallSetting.media = JSON.stringify(
            this.defaultMedia
          );
        }
      }
    });
  }
  setWindowTitle(title: string) {
    document.title = title + " | Primas Bot Platform";
  }
  setDefaultSetting() {
    this.http
      .get<any[]>("assets/sample-file/media-data.json")
      .subscribe((rs) => {
        this.defaultMedia = rs;
        if (!this.dataService.CallSetting) {
          let callSetting = new CallSettingDto();
          callSetting.enableRecording = false;
          callSetting.allowLogInfo = true;
          callSetting.speechToTextService = "Azure";
          callSetting.transcribeService = "Azure";
          callSetting.languageSetting = JSON.stringify([
            {
              name: "English (United States)",
              service: "4",
              language: "en-US",
              voiceId: "en-US-JennyNeural",
              gender: "Female",
              defaultUser: true,
            },
          ]);
          callSetting.prompts = JSON.stringify({
            error: "Sorry something went wrong. Please try again later!",
          });
          callSetting.media = JSON.stringify(this.defaultMedia);
          callSetting.botContact = "";
          callSetting.botName = "";
          callSetting.botGreetingMessages = "";
          callSetting.botLogo = "";
          callSetting.defaultLanguage = JSON.stringify({});
          callSetting.detectLanguage = false;
          callSetting.translateService = ServiceTranslate.Google;
          this.dataService.CallSetting = callSetting;
        } else {
          if (!this.dataService.CallSetting.media) {
            this.dataService.CallSetting.media = JSON.stringify(
              this.defaultMedia
            );
          }
        }
      });
  }

  setIvrAttribute() {
    if (this.data.attribute != undefined && this.data.attribute !== "null") {
      let json = JSON.parse(this.data.attribute);
      this.dataService.ivrAttribute = [];
      json.forEach((element) => {
        if (element.default == 0 && element["content"] == undefined) {
          element["content"] = "";
        }
        this.dataService.ivrAttribute.push(element);
      });
    }
  }
  back() {
    if (this.isReadOnly != true) {
      const isFlowChanged = this.checkGraphChanged(
        this.draft,
        this.callflowGraph,
        this.dataService
      );
      if (this.isChanged && isFlowChanged) {
        this.dialogService
          .open(AlertDialogComponent, {
            autoFocus: false,
            context: {
              title: "Notifications",
              question:
                "The flow has changed. Do you want to exit without saving the changes?",
              textYes: "Yes",
              textNo: "Cancel",
              statusYes: "info",
              statusNo: "basic",
            },
          })
          .onClose.subscribe((isConfirm) => {
            if (isConfirm) {
              this.callFlowClient
                .outCallFlow(this.data.id)
                .subscribe((result) => {});
              this.router.navigate([`/portal/studio/callflows`]);
            }
          });
      } else {
        this.callFlowClient.outCallFlow(this.data.id).subscribe((result) => {});
        this.router.navigate([`/portal/studio/callflows`]);
      }
    } else {
      this.router.navigate([`/portal/studio/callflows`]);
    }
  }

  checkGraphChanged(draft, callflowGraph, dataService) {
    let callflowXML = callflowGraph
      .getXml()
      .replaceAll(" ", "")
      .replaceAll('edge="1"', "")
      .replaceAll('vertex="1"', "")
      .toLowerCase();
    let dataServiceXML = dataService?.CallFlow?.data
      .replaceAll(" ", "")
      .replaceAll(" ", "")
      .replaceAll('edge="1"', "")
      .replaceAll('vertex="1"', "")
      .toLowerCase();
    if (
      (draft?.id &&
        (callflowGraph.getXml() != draft?.data ||
          callflowGraph.getFlow() != draft?.jsonFormat)) ||
      (!draft?.id &&
        (callflowXML != dataServiceXML ||
          callflowGraph.getFlow() != dataService?.CallFlow?.jsonFormat))
    ) {
      return true;
    }
    return false;
  }

  importCheckFlow(files: any) {
    try {
      if (files.length === 0) return;
      var mimeType = files[0]?.type;
      if (
        mimeType.match(/application\/x-zip-compressed/) == null &&
        mimeType.match(/application\/zip/) == null
      ) {
        this.toastrService.show(
          "Only ZIP formats are allowed",
          `Notification`,
          {
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
            status: "danger",
          }
        );
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.callFlowClient
          .checkFileImport(
            new CheckFileImportCallFlowCommand({
              callFlowData: reader.result.toString(),
            })
          )
          .subscribe((rs) => {
            this.dialogService
              .open(ImportFlowModalComponent, {
                autoFocus: false,
                context: { dataImport: rs },
              })
              .onClose.subscribe((data) => {
                if (data) {
                  this.handleImport(reader, data, files);
                }
              });
          });
      };
      this.fileFlow = null;
    } catch (error) {
      this.toastrService.show(error?.message, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  handleImport(reader, data, files) {
    let importProgress = this.dialogService.open(ImportFlowResultComponent, {
      autoFocus: false,
      closeOnBackdropClick: false,
      closeOnEsc: false,
      context: {
        callFlowData: reader.result.toString(),
        path: data.dataImport,
      },
    });
    importProgress.onClose.subscribe((rs) => {
      if (rs == "try-again") {
        this.importCheckFlow(files);
      }
    });
    importProgress.componentRef.instance.onComplete.subscribe((rs) => {
      this.callflowGraph.importFlowHandler(rs.jsonFormat);
      if (rs.attribute) {
        let json = JSON.parse(rs.attribute);
        this.dataService.ivrAttribute = [];
        json.forEach((element) => {
          if (data.conflictValue.length > 0) {
            const dataConfig = data.conflictValue.find(
              (x) => x.old.value == element.value
            );
            if (dataConfig && !dataConfig.isOverride) {
              element.content = dataConfig.old.content;
            }
          }
          this.dataService.ivrAttribute.push(element);
        });
      }
      if (rs.callSetting) {
        const oldId = this.dataService.CallSetting.id;
        const oldBotContact = this.dataService.CallSetting.botContact;
        const callFlowId = this.dataService.CallSetting.callFlowId;
        this.dataService.CallSetting = JSON.parse(rs.callSetting);
        this.dataService.CallSetting.callFlowId = callFlowId;
        this.dataService.CallSetting.id = oldId;
        this.dataService.CallSetting.botContact = oldBotContact;
        this.callSettingImport = true;
      }
    });
  }
  importFlow(files: any) {
    try {
      if (files.length === 0) return;
      var mimeType = files[0]?.type;
      if (mimeType.match(/application\/x-zip-compressed/) == null) {
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.callFlowClient
          .import(
            new ImportCallFlowCommand({
              callFlowData: reader.result.toString(),
            })
          )
          .subscribe((rs) => {
            this.callflowGraph.importFlowHandler(rs.jsonFormat);
            if (rs.attribute) {
              let json = JSON.parse(rs.attribute);
              this.dataService.ivrAttribute = [];
              json.forEach((element) =>
                this.dataService.ivrAttribute.push(element)
              );
            }
          });
      };
      this.fileFlow = null;
    } catch (error) {
      this.toastrService.show(error?.message, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }

  exportFlowConfirm() {
    let listSubflow = this.callflowGraph.getAllSubflowNameUsed();
    if (listSubflow.length > 0) {
      let count = listSubflow.length;
      let s = count > 1 ? "s:" : ":";
      let message = "Your flow is using " + count + " subflow" + s;
      let questionList = [];
      listSubflow.forEach((element) => {
        questionList.push(element.name);
      });

      this.dialogService
        .open(AlertDialogComponent, {
          autoFocus: false,
          context: {
            title: "Notifications",
            questionAdd: message,
            questionAddList: questionList,
            question: "Do you want to include all subflow in the export?",
            textYes: "Yes",
            textNo: "No",
            statusYes: "info",
            statusNo: "basic",
            allowCancel: true,
          },
        })
        .onClose.subscribe((isConfirm) => {
          if (isConfirm == true) {
            this.exportFlow();
          }
          if (isConfirm == false) {
            this.exportFlow(false);
          }
        });
    } else {
      this.exportFlow();
    }
  }
  exportFlow(includeSubflow = true) {
    this.popover?.hide();
    this.callFlowClient
      .export(
        new ExportCallFlowCommand({
          audiosId: this.callflowGraph.getAllAudioIdUsed(),
          gptList: this.callflowGraph.getAllChatGPTUsed(),
          grammarList: this.callflowGraph.getAllGrammarUsed(),
          functionId: this.callflowGraph.getAllFunctionUsed(),
          reportList: this.callflowGraph.getAllReportUsed(),
          callFlowScript: this.callflowGraph.getXml(),
          subflowList: includeSubflow
            ? this.callflowGraph.getAllSubflowUsed()
            : [],
          attribute: JSON.stringify(this.dataService.ivrAttribute),
          callSetting: JSON.stringify(this.dataService.CallSetting),
          cmsList: this.callflowGraph.getAllCMSUsed(),
          emailTemplateList: this.callflowGraph.getAllEmailTemplateUsed(),
        })
      )
      .subscribe((rs) => {
        let file = this.dataURLtoFile("data:application/zip;base64," + rs);
        let name = this.dataService.CallFlow.name
          ? this.dataService.CallFlow.name
          : "callflow";
        let ext = includeSubflow ? "_subflow.zip" : ".zip";
        this.downloadData(name + ext, file);
      });
  }

  exportJson() {
    let blob = new Blob([this.callflowGraph.getFlow()], {
      type: "application/json",
    });
    saveAs(blob, "flow.json");
    let setting = new Blob(
      [JSON.stringify(this.dataService.CallFlow.callSettings[0])],
      {
        type: "application/json",
      }
    );
    saveAs(setting, "flow-setting.json");
  }

  settingModal() {
    const callSetting = this.dialogService.open(SettingCallFlowsComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
      autoFocus: false,
      context: {
        callSetting: { ...this.dataService.CallSetting },
        languageDefault: this.callflowGraph.languageDefault,
        isReadOnly: this.isReadOnly,
      },
    });
    callSetting.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs.result) {
        this.isSetting = rs.result;
        this.publish();
      }
    });
  }
  logModal() {
    const logModal = this.dialogService.open(LogModalComponent, {
      autoFocus: false,
      context: { isReadOnly: this.isReadOnly },
    });
  }
  dataURLtoFile(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "", { type: mime });
  }

  downloadData(filenameForDownload: string, data: any) {
    var textUrl = URL.createObjectURL(data);
    var element = document.createElement("a");
    element.setAttribute("href", textUrl);
    element.setAttribute("download", filenameForDownload);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  makeATestCall() {
    this.eventBusService.emit(new EventData("closeDebugMode", true));
    this.dialogService
      .open(ChooseTestNumberComponent, {
        autoFocus: false,
        context: { isDebug: true },
      })
      .onClose.subscribe((data) => {
        if (data !== undefined && data !== false) {
          this.run(data.outGoingNumber, data.callPhoneNumber, data.isDebug);
        }
      });
  }

  makeATestChat() {
    this.eventBusService.emit(new EventData("closeDebugMode", true));
    this.dataService.openChatContent = false;
    this.chatControlEvent(this.showChatBtn);
  }

  chatControlEvent(isOpen) {
    if (isOpen) {
      this.showChatBtn = false;
      this.sendFlowChat();
    } else {
      this.showChatBtn = true;
    }
  }
  sendFlowChat() {
    const callSetting = {
      ...this.dataService.CallSetting,
      companyId: this.dataService.CompanyId,
    };
    var callFlowJson = this.callflowGraph.getFlow();
    var callSettings = JSON.stringify(callSetting);
    var attribute = JSON.stringify(this.dataService.ivrAttribute);
    setTimeout(() => {
      const randomId = generateRandomString(10);
      this.chatComponent.sendChatFlow(callFlowJson, callSettings, attribute);
      this.chatComponent.link = this.openChatbotUrl(randomId);
      // let sss = document.getElementById("primas-chat-frame") as any;
      // sss.onload = () => {
      //   sss.contentWindow.postMessage(
      //     {
      //       type: "RUN_FLOW",
      //       value: { callFlowJson, callSettings, attribute },
      //     },
      //     "*"
      //   );
      // };
      this.dialogService.open(DebugModalComponent, {
        autoFocus: false,
        context: {
          id: randomId,
        },
        dialogClass: "modal-margin-top",
        hasBackdrop: false,
      });
    }, 100);
  }
  helpCenter() {
    this.router.navigate([]).then((result) => {
      window.open(this.linkDocumentation, "_blank");
    });
  }
  generateChatbotQR() {
    this.dialogService.open(GenerateQRComponent, {
      autoFocus: false,
      dialogClass: "modal-center",
      context: {
        data: this.data,
      },
    });
  }

  openChatbotUrl(chatId) {
    let botName =
      this.dataService.CallSetting?.botName?.trim() ||
      this.dataService.CallFlow.name;
    let logo = this.dataService.CallSetting?.botLogo?.trim()
      ? "&logoUrl=" + this.dataService.CallSetting.botLogo.trim()
      : "";
    let greeting = this.dataService.CallSetting?.botGreetingMessages?.trim()
      ? "&greetingMessage=" +
        this.dataService.CallSetting.botGreetingMessages.trim()
      : "";
    const url = (
      this.dataService.CallFlow.chatUrl +
      "&name=" +
      botName +
      logo +
      greeting +
      "&color=3367d6" +
      `&callFlowTestId=${chatId}`
    ).replace(
      /(&triggerChatFlow=1|&restartConv=1|triggerChatFlow=1&|restartConv=1&)/g,
      ""
    );

    return url;
  }
  openWindow() {
    this.dialogService.open(RevisionHistoryComponent, {
      autoFocus: false,
      context: {
        callflow: this.data,
        isReadOnly: this.isReadOnly,
      },
    });
  }
  run(outGoingNumber, callPhoneNumber, isDebug) {
    this.callflowGraph.closeActionWindow();
    this.callFlowClient
      .run(
        new MakeTestCallCommand({
          id: this.data.id,
          callFlowJson: this.callflowGraph.getFlow(),
          callNumber: callPhoneNumber,
          phoneNumber: outGoingNumber,
          callSettings: JSON.stringify(this.dataService.CallSetting),
          attribute: JSON.stringify(this.dataService.ivrAttribute),
          isDebug: isDebug,
        })
      )
      .subscribe((rs) => {
        if (rs && rs.status) {
          this.toastrService.show(
            "A test call is made to your number",
            `Notification`,
            {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "success",
            }
          );
          if (rs.uniqueId) {
            this.dialogService.open(DebugModalComponent, {
              autoFocus: false,
              context: {
                id: rs.uniqueId,
              },
              dialogClass: "modal-margin-top",
              hasBackdrop: false,
            });
          }
        } else {
          this.toastrService.show(
            "Something wrong is happening",
            `Notification`,
            {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "danger",
            }
          );
        }
      });
  }
  saveNumberFlow(callflowId: number, numbers: string) {
    this.numberClient
      .assignCallFlow(
        new AssignCallFlowCommand({
          callFlowId: callflowId,
          phoneNumbers: numbers,
        })
      )
      .subscribe((rs) => {
        if (rs) {
          // this.eventBusService.emit(new EventData('chooseCallFlow', callflowId));
        }
      });
  }
  updateCallSettingImport() {
    this.callSettingClient.update(this.dataService.CallSetting).subscribe(
      (rs) => {
        this.callSettingImport = false;
      },
      (error) => {
        this.callSettingImport = false;
      }
    );
  }
  save(cb = null) {
    if (this.callSettingImport) {
      this.updateCallSettingImport();
    }
    this.callflowGraph.closeActionWindow();
    if (this.callFlowNameForm.valid) {
      if (this.data.id !== undefined) {
        this.isSaveLoading = true;
        this.createNewDraft()
          .then(() => {
            this.autoSave()
              .then((result) => {
                this.isSaveLoading = false;
                if (result) {
                  this.isChanged = false;
                  this.toastrService.show(
                    "Save flow successfully",
                    `Notification`,
                    {
                      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                      status: "success",
                    }
                  );
                  if (cb) cb();
                } else {
                  this.toastrService.show(
                    "Save flow unsuccessfully",
                    `Notification`,
                    {
                      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                      status: "danger",
                    }
                  );
                  if (cb) cb();
                }
              })
              .catch(() => {
                this.toastrService.show(
                  "Save flow unsuccessfully",
                  `Notification`,
                  {
                    position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                    status: "danger",
                  }
                );
                if (cb) cb();
              });
          })
          .catch(() => {
            this.toastrService.show(
              "Save flow unsuccessfully",
              `Notification`,
              {
                position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                status: "danger",
              }
            );
            if (cb) cb();
          });
      }
    } else {
      this.toastrService.show("Save flow unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
      if (cb) cb();
    }
    this.chatControlEvent(false);
  }

  checkCallFLowName(value) {
    if (value != "" && value != this.currentName) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.isExist = false;
        this.isNameLoading = true;
        let data = new CheckCallFlowNameCommand();
        data.callFlowName = value?.trim();
        this.callFlowClient.checkExistCallFlowName(data).subscribe(
          (rs) => {
            if (rs && value?.trim() != this.currentName) {
              this.callFlowNameForm.controls["name"].setErrors({
                isExist: rs,
              });
              this.isExist = true;
            } else {
              this.isExist = false;
            }
            this.handleFocus();
            this.isNameLoading = false;
          },
          (error) => {
            this.handleFocus();
          }
        );
        this.data.name = this.callFlowNameForm.controls["name"]?.value
          ?.toString()
          ?.trim();
      }, 1500);
    }
  }
  handleFocus() {
    setTimeout(() => {
      this.inputName?.nativeElement?.focus();
    }, 0);
  }
  revertRevisionHandle(draft: DraftDto) {
    //if (this.draft?.id) {
    this.isAutoSave = false;
    //this.draft = draft;
    this.data.attribute = draft.attribute;
    this.callflowGraph.importFlowHandler(draft.data);
    this.setIvrAttribute();
    this.createNewDraft();
    this.windowRef?.close();
    this.toastrService.show("Revert revision successfully", `Notification`, {
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      status: "success",
    });
    //}
  }

  createNewDraft() {
    return new Promise((resolve, reject) => {
      if (this.data?.id) {
        if (this.draft == null) {
          this.data.name = this.data.name.trim();
          this.currentName = this.data.name.trim();
          var svg = this.graphHandler.handleRevisionImage(this.graph);
          let createDraft = new CreateDraftCommand({
            data: this.callflowGraph.getXml(),
            name: this.data.name.trim(),
            callflowId: this.data.id,
            image: svg,
            attribute: JSON.stringify(this.dataService.ivrAttribute),
            jsonFormat: this.callflowGraph.getFlow(),
          });
          this.draftClient.create(createDraft).subscribe({
            next: (result) => {
              if (result) {
                this.draft = result;
                this.currentName = result.name;
                this.setWindowTitle(result.name);
                resolve(result);
              } else {
                this.toastrService.show(
                  "Create New Revision Failed",
                  `Notification`,
                  {
                    position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                    status: "danger",
                  }
                );
              }
            },
            error: () => {
              reject();
            },
          });
        } else {
          this.autoSave().then(() => {
            resolve(null);
          });
        }
      }
    });
  }
  publish() {
    this.callflowGraph.destroyPopupMenu();
    if (this.callFlowNameForm.valid) {
      if (this.isSetting) {
        this.callflowGraph.closeActionWindow();
        this.handlePublish();
      } else {
        this.dialogService
          .open(ConfirmDialogComponent, {
            autoFocus: false,
            context: {
              question: "Are you sure you want to publish this version?",
              textYes: "Yes",
              textNo: "Cancel",
              statusYes: "info",
              statusNo: "basic",
            },
          })
          .onClose.subscribe((isConfirm) => {
            if (isConfirm) {
              this.callflowGraph.closeActionWindow();
              this.handlePublish();
            }
          });
      }
    } else {
      this.toastrService.show("Publish flow unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
    this.chatControlEvent(false);
  }
  handlePublish() {
    this.isPublishLoading = true;
    this.createNewDraft()
      .then(() => {
        if (this.draft?.id) {
          let publishDraft = new PublishDraftCommand({
            id: this.draft.id,
            callowId: this.draft.callflowId,
            isPublished: true,
          });
          this.draftClient.publish(publishDraft).subscribe((rs) => {
            if (rs) {
              this.data.name = this.data.name.trim();
              this.currentName = this.data.name.trim();
              let updateCallflow = new UpdateCallFlowCommand({
                companyId: this.data.companyId,
                id: this.data.id,
                name: this.data.name.trim(),
                attribute: JSON.stringify(this.dataService.ivrAttribute),
                data: this.callflowGraph.getXml(),
                jsonFormat: this.callflowGraph.getFlow(),
                tags: this.data.tags,
              });
              this.callFlowClient.update(updateCallflow).subscribe({
                next: (rs) => {
                  if (rs) {
                    this.draft = null;
                    this.dataService.CallFlow.data = updateCallflow.data;
                    this.dataService.CallFlow.jsonFormat =
                      updateCallflow.jsonFormat;
                    this.dataService.CallFlow.attribute =
                      updateCallflow.attribute;
                    this.isChanged = false;
                    this.changedDate = new Date();
                    this.savedStatus = "All change saved";
                    let messSuccess = "Publish Draft To Callflow Success";
                    if (this.isSetting) {
                      messSuccess = "Save setting successfully";
                      this.isSetting = false;
                    }
                    this.toastrService.show(messSuccess, `Notification`, {
                      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                      status: "success",
                    });
                  } else {
                    let messFails = "Something wrong when Publish Callflow";
                    if (this.isSetting) {
                      messFails = "Save setting unsuccessfully";
                      this.isSetting = false;
                    }
                    this.toastrService.show(messFails, `Notification`, {
                      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                      status: "danger",
                    });
                  }
                  this.isPublishLoading = false;
                },
                error: () => {
                  this.isPublishLoading = false;
                  this.toastrService.show(
                    "Something wrong when Publish Callflow",
                    `Notification`,
                    {
                      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                      status: "danger",
                    }
                  );
                },
              });
            } else {
              this.isPublishLoading = false;
              this.toastrService.show(
                "Something wrong when Publish Callflow",
                `Notification`,
                {
                  position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                  status: "danger",
                }
              );
            }
          });
        }
      })
      .catch(() => {
        this.isPublishLoading = false;
        this.toastrService.show(
          "Something wrong when Publish Callflow",
          `Notification`,
          {
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
            status: "danger",
          }
        );
      });
  }
  autoSave() {
    return new Promise((resolve, reject) => {
      // if (this.draft?.id && this.isAutoSave == true) {
      if (this.draft?.id) {
        this.draft.name = this.data.name.trim();
        var svg = this.graphHandler.handleRevisionImage(
          this.callflowGraph.getGraph()
        );
        let updateDraft = new UpdateDraftCommand({
          id: this.draft.id,
          name: this.draft.name.trim(),
          callflowId: this.draft.callflowId,
          data: this.callflowGraph.getXml(),
          attribute: JSON.stringify(this.dataService.ivrAttribute),
          jsonFormat: this.callflowGraph.getFlow(),
          version: this.draft.version,
          image: svg,
        });
        this.draftClient.update(updateDraft).subscribe({
          next: (result) => {
            if (result) {
              this.isChanged = false;
              this.draft.data = updateDraft.data;
              this.draft.jsonFormat = updateDraft.jsonFormat;
              this.currentName = this.draft.name.trim();
              this.changedDate = new Date();
              this.savedStatus = "All change saved";
              this.setWindowTitle(this.draft.name.trim());
              resolve(true);
            } else {
              resolve(false);
            }
          },
          error: () => {
            reject();
          },
        });
      } else {
        //this.isAutoSave = true;
        resolve(false);
      }
    });
  }

  askModifyFlow(userName) {
    this.dialogService
      .open(RequestModificationDialogComponent, {
        autoFocus: false,
        context: {
          processRequest: "response",
          toUser: userName,
          question: "You have a notification",
        },
      })
      .onClose.subscribe((result) => {
        if (result == "grant") {
          let isChanged = false;
          if (
            this.draft?.id &&
            (this.callflowGraph.getXml() != this.draft.data ||
              this.callflowGraph.getFlow() != this.draft.jsonFormat)
          ) {
            isChanged = true;
          } else if (
            !this.draft?.id &&
            (this.callflowGraph.getXml() != this.dataService.CallFlow.data ||
              this.callflowGraph.getFlow() !=
                this.dataService.CallFlow.jsonFormat)
          ) {
            isChanged = true;
          }
          if (isChanged) {
            this.save(() => {
              this.callFlowClient
                .outCallFlow(this.data.id)
                .subscribe((result) => {
                  this.callFlowClient
                    .outCallFlow(this.data.id)
                    .subscribe((rs) => {
                      this.callFlowClient
                        .requestModificationFlow(
                          new RequestModificationFlowCommand({
                            requestFromUser: this.dataService.userName,
                            requestToUser: userName,
                            type: "grant",
                          })
                        )
                        .subscribe((result) => {
                          this.router.navigate([`/portal/studio/callflows`]);
                        });
                    });
                });
            });
          } else {
            this.callFlowClient
              .outCallFlow(this.data.id)
              .subscribe((result) => {
                this.callFlowClient
                  .outCallFlow(this.data.id)
                  .subscribe((rs) => {
                    this.callFlowClient
                      .requestModificationFlow(
                        new RequestModificationFlowCommand({
                          requestFromUser: this.dataService.userName,
                          requestToUser: userName,
                          type: "grant",
                        })
                      )
                      .subscribe((result) => {
                        this.router.navigate([`/portal/studio/callflows`]);
                      });
                  });
              });
          }
        } else if (result == "deny") {
          this.callFlowClient
            .requestModificationFlow(
              new RequestModificationFlowCommand({
                requestFromUser: this.dataService.userName,
                requestToUser: userName,
                type: "deny",
              })
            )
            .subscribe((result) => {});
        }
      });
  }

  checkCallFlowDesign(id, name) {
    this.callFlowClient.checkUserIsDesigning(id).subscribe((userName) => {
      if (userName == this.dataService.userName) {
        this.isAllowDesign = true;
      } else {
        this.dialogService
          .open(RequestModificationDialogComponent, {
            autoFocus: false,
            context: {
              question: `Flow "${name}" is being used by ${
                userName || "someone else"
              }`,
              fromUser: this.dataService.userName,
              toUser: userName,
              processRequest: "request",
            },
            hasBackdrop: true,
            closeOnEsc: false,
            closeOnBackdropClick: false,
          })
          .onClose.subscribe((response) => {
            if (response == "savecopy") {
              let createRequest = new DuplicateCallFlowCommand({
                data: this.callflowGraph.getXml(),
                name: this.data.name.trim() + " - copy",
                isSubFlow: this.data.isSubFlow,
                attribute: JSON.stringify(this.dataService.ivrAttribute),
                jsonFormat: this.callflowGraph.getFlow(),
                enableRecording: this.dataService.CallSetting.enableRecording,
                transcribeService:
                  this.dataService.CallSetting.transcribeService,
                prompts: this.dataService.CallSetting.prompts,
                allowLogInfo: this.dataService.CallSetting.allowLogInfo,
                languageSetting: this.dataService.CallSetting.languageSetting,
                speechToTextService:
                  this.dataService.CallSetting.speechToTextService,
                translateService: this.dataService.CallSetting.translateService,
                defaultLanguage: this.dataService.CallSetting.defaultLanguage,
                detectLanguage: this.dataService.CallSetting.detectLanguage,
                botName: this.dataService.CallSetting.botName,
                botGreetingMessages:
                  this.dataService.CallSetting.botGreetingMessages,
                botLogo: this.dataService.CallSetting.botLogo,
              });
              this.callFlowClient
                .duplicate(createRequest)
                .subscribe((result) => {
                  if (result) {
                    this.data = result;
                    //this.createNewDraft();
                    this.saveNumberFlow(
                      result.id,
                      this.dataService.CallSetting.selectedPhoneNumbers
                    );
                    this.dataService.CallFlow = result;
                    this.dataService.CallSetting = result.callSettings[0];
                    this.isAllowDesign = true;
                    this.currentName = result?.name;
                    this.createNewDraft();
                    window.history.replaceState(
                      {},
                      "",
                      `callflow-design/${result.id}`
                    );
                    this.toastrService.show(
                      "Save as flow successfully",
                      `Notification`,
                      {
                        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                        status: "success",
                      }
                    );
                  } else {
                    this.toastrService.show(
                      "Somethings went wrong, try to save again",
                      `Notification`,
                      {
                        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                        status: "danger",
                      }
                    );
                  }
                });
            } else if (response == "grant") {
              const currentUrl = this.router.url;
              this.callFlowClient
                .requestModificationFlow(
                  new RequestModificationFlowCommand({
                    requestFromUser: this.dataService.userName,
                    requestToUser: userName,
                    type: "grant",
                    callFlowId: this.data.id,
                  })
                )
                .subscribe((result) => {
                  this.router
                    .navigateByUrl("/portal/studio/callflows", {
                      skipLocationChange: true,
                    })
                    .then(() => {
                      this.router.navigate([currentUrl]);
                    });
                });
            } else if (response == "readonly") {
              this.isAllowDesign = true;
              this.isReadOnly = true;
              return true;
            } else {
              this.router.navigate([`/portal/studio/callflows`]);
            }
          });
      }
    });
  }
}
